import { Dialog } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { useSearchesContext } from '../contexts/SearchesProvider';
import { useSelectionContext } from '../contexts/SelectionProvider';
import { auth } from '../firebase.config';
import { Transition, deleteChat } from '../helper/functions';
import { ChatPartnetLeft, Delete, Dot } from '../helper/icons';

const Chats = () => {

const { customerChats } = useStateContext();
const { customerSearches } = useSearchesContext();

const objectChats = customerChats.filter(chat => chat.agentID === auth.currentUser.uid);
const objectsFromChats = new Set(objectChats.map(chat => chat.object));
const uniqueObjects = Array.from(objectsFromChats);

const searchChats = customerChats.filter(chat => chat.agentID !== auth.currentUser.uid);
const searchesFromChats = new Set(searchChats.map(chat => chat.search));
const uniqueSearches = Array.from(searchesFromChats);
const deletedUniqueSearches = uniqueSearches.filter(uniqueSearch => !customerSearches.some(e => e.id === uniqueSearch));

return (
    <div className='flex flex-col items-center px-2 w-full md:w-1/2 overflow-auto pt-2 ssm:pt-10'>
        {uniqueSearches.filter(uniqueSearch => customerSearches.some(e => e.id === uniqueSearch)).map((search, index) => {
            return (
                <div key={index} className='w-full flex flex-col gap-1'>
                    <p className='text-allimmoDark'>{`${t('Chats.Search')} "${customerSearches.find(e => e.id === search)?.name}"`}</p>
                    {customerChats.filter(ch => ch.search === search).map((ch, i) => {
                        return (
                            <ChatObject key={i} data={ch}/>
                        )
                    })}
                </div>
            )
        })}
    
        {deletedUniqueSearches.length > 0 &&
        <div className='flex flex-col items-center justify-center w-full'>
            <p className='text-allimmoDark self-start mt-5'>{t('Chats.SearchDeleted')}</p>
            {deletedUniqueSearches.map((search, index) => {
                return (
                    <div key={index} className='w-full flex flex-col gap-1 mt-1'>
                        {customerChats.filter(ch => ch.search === search).map((ch, i) => {
                            return (
                                <ChatObject key={i} data={ch}/>
                            )
                        })}
                    </div>
                )
            })}
        </div>}

        <div className='flex flex-col items-center w-full overflow-auto mt-10'>
            {uniqueObjects.map((objectChat, index) => {
                return (
                    <div key={index} className='w-full flex flex-col gap-1'>
                        <p className='text-allimmoDark'>{`${t('Chats.Object')} "${objectChats.find(e => e.object === objectChat).address}"`}</p>
                        {objectChats.filter(ch => ch.object === objectChat).map((ch, i) => {
                            return (
                                <ChatObject key={i} data={ch} showAddress={false} />
                            )
                        })}
                    </div>
                )
            })}
        </div>
    </div>
  )
};

const ChatObject = ({data, showAddress = true}) => {

    const { setChatDetailsOpen, setChatSelection, setShowObjectBtn } = useSelectionContext();
    const { screenSize, setIsLoading, customerChats, handleMenuState } = useStateContext();

    const [chatDeleteConfirmOpen, setChatDeleteConfirmOpen] = useState(false);

    const chatPartnerId = data?.members.find(m => m !== auth.currentUser.uid);
    const chatPartnerLeft = data?.deletedMembers && data?.deletedMembers.find(m => m.member === chatPartnerId);

    const handleChatOpen = () => {
        setShowObjectBtn(true);
        setChatSelection(data.id);
        setChatDetailsOpen(true);
    };

    const deleteThisChat = async () => {
        setChatDeleteConfirmOpen(false);
        setIsLoading(true);
        await deleteChat({chatId: data.id})
        .then(() => {
            if (customerChats.length === 0) {
                handleMenuState("dashboard");
            }
            setIsLoading(false);
        })
        .catch(() => {
            setIsLoading(false);
        })
    };

    const handleChatDelete = (e) => {
        e.stopPropagation();
        setChatDeleteConfirmOpen(true);
    };

    const backdropClose = (event, reason) => {
        if (reason && event === "backdropClick") 
            return;
        setChatDeleteConfirmOpen(false);
    };
    
    return (
        <>
        <div onClick={handleChatOpen} className='flex flex-row w-full items-center rounded border bg-white border-allimmoDark py-2 px-3 cursor-pointer hover:bg-sky-50 gap-1'>
            {chatPartnerLeft && 
            <ChatPartnetLeft size={18} className='text-red-400 mr-1.5' />}
            <p className={`text-allimmoDark font-bold ${showAddress ? "" : "grow"}`}>{data?.data[data?.members.find(m => m !== auth.currentUser.uid)].name}</p>
            {showAddress && <p>-</p>}
            {(screenSize.width >= 400 && showAddress) && <p className='grow'>{data?.address}</p>}
            {(screenSize.width < 400 && showAddress) && <p className='grow'>{data?.address.length > 23 ? (data?.address.substring(0, 20) + '...') : data?.address}</p>}
            <Delete size={18} className='text-slate-400 hover:text-slate-600 text-end' onClick={(e) => handleChatDelete(e)}/>
            {data.newMsg.includes(auth.currentUser.uid) && <Dot className={`text-red-500`} size={18} />}
        </div>

        <Dialog onClose={(e, r) => backdropClose(e,r)} open={chatDeleteConfirmOpen} TransitionComponent={Transition}>
            <div className='flex flex-col justify-center items-center p-5 select-none w-full'>
                <p className="text-center w-full">{t("DeleteChat.Text")}</p>
                <div className='flex flex-row justify-center mt-8 w-full gap-2'>
                    <button className='bg-allimmoDark/80 rounded text-white px-3 py-2 cursor-pointer hover:bg-allimmoDark active:bg-allimmoDark/80' onClick={() => setChatDeleteConfirmOpen(false)}>{t('General.Abbrechen')}</button>
                    <button className='bg-allimmoDark/80 rounded text-white px-3 py-2 cursor-pointer hover:bg-allimmoDark active:bg-allimmoDark/80' onClick={(e) => deleteThisChat(e)}>{t('General.Löschen')}</button>
                </div>
            </div>
        </Dialog>
        </>
    )
};

export default Chats
