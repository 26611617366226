import { Checkbox, CircularProgress, Collapse, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { addCredits, deleteUserData, getCustomerEmails, sendUpdateEmail, userCreateAdmin } from '../helper/functions';
import validator from 'validator';
import Button from '../components/Generics/Button';

const Admin = () => {

    const { adminEmail } = useStateContext();

    const memeberEmails = [adminEmail, "vivpour@gmail.com", "vp@allimmo.at", "juleswimmer@gmail.com", "joshuakrick@hotmail.de"];
    const initExtendedTools = {
        email: false,
        credits: false,
        update: false,
        delete: false,
        createUser: false,
    };

    const deleteOptions = {
        userData: true,
        searches: true,
        chats: true,
    }

    const [withAdminEmails, setWithAdminEmails] = useState(false);
    const [emalList, setEmalList] = useState([]);
    const [isLoadingEmails, setIsLoadingEmails] = useState(false);
    const [isLoadingAddCredits, setIsLoadingAddCredits] = useState(false);
    const [updateEmailLoading, setUpdateEmailLoading] = useState(false);
    const [extendedTools, setExtendedTools] = useState(initExtendedTools);
    const [creditData, setCreditData] = useState({
        email: "",
        amount: 0,
    });

    const [createUserData, setCreateUserData] = useState({
        name: "",
        email: "",
        phone: "",
        password: "",
        credits: "2",
    })
    const [addedData, setAddedData] = useState({});
    const [infoText, setInfoText] = useState("");
    const [templateId, setTemplateId] = useState("");
    const [updateText, setUpdateText] = useState("");  
    
    const [deleteEmail, setDeleteEmail] = useState("");
    const [deleteText, setDeleteText] = useState("");
    const [deleteLoading, setDeleteLoading] = useState(false);

    const getEmails = async () => {
        setIsLoadingEmails(true);
        const emails = await getCustomerEmails({adminEmails: withAdminEmails ? ["123"] : [...memeberEmails]});
        if(emails) {
            setIsLoadingEmails(false);
            setEmalList(emails.data);
        }
    };

    const extendTool = (tool) => {
        setExtendedTools({...initExtendedTools, [tool]: !extendedTools[tool]})
    };

    const addUserCredits = async () => {
        if(parseInt(creditData.amount) === 0 || creditData.email.trim().length === 0 || creditData.amount.toString().trim().length === 0) return setInfoText("input");
        
        setIsLoadingAddCredits(true);
        setInfoText("");
        setAddedData({...creditData});
        
        await addCredits({email: creditData.email, amount: parseInt(creditData.amount)})
        .then((res) => {
            if(res.data === "success") {
                setInfoText("success");
                setCreditData({email: "", amount: 0});
            }

            if(res.data === "error") {
                setInfoText("error");
            }

            if(res.data === "input") {
                setInfoText("input");
            }
            setIsLoadingAddCredits(false);
        })
        .catch((err) => {
            console.log(err)
            setIsLoadingAddCredits(false);
        })
    };

    const sendUpdateEmaill = async () => {
        if(templateId.trim().length !== 34) return;
        if(!templateId.trim().startsWith("d-")) return;
        setUpdateEmailLoading(true);

        await sendUpdateEmail({templateId: templateId})
        .then((res) => {
            setTemplateId("");
            setUpdateText(`${res.data.amount} Erfolgreich | ${res.data.error} Fehler`);
        })
        .catch((err) => {
            setUpdateText("Email konnte nicht gesendet werden!", err.message);
        });
        setUpdateEmailLoading(false);
    };

    const deleteUser = async () => {
        if(deleteEmail.trim() === adminEmail) return setDeleteText("Allimmo Account kann nicht gelöscht werden!");
        setDeleteLoading(true);
        await deleteUserData({email: deleteEmail.trim(), options: deleteOptions})
        .then((res) => {
            setDeleteText(res.data);
            if(res.data === "success") {
                setDeleteEmail("");
            }
        });
        setDeleteLoading(false);
    };

    const createUserAdmin = async () => {
        // if (Object.values(createUserData).some((v) => v.trim().length === 0)) return;
        console.log(createUserData);
        await userCreateAdmin({...createUserData, emailVerified: true});
    };

  return (
    <div className='flex flex-col items-center gap-1 w-full'>
        <div onClick={() => extendTool('email')} className='gap-2 items-center flex flex-row w-200 rounded border border-allimmoDark cursor-pointer hover:bg-allimmoDark/10 px-2'>
            <p>+</p>
            <p>Email Adressen</p>
        </div>
        <Collapse in={extendedTools.email}>
            <div className='gap-2 flex flex-row items-center'>
                <Checkbox checked={withAdminEmails} onChange={() => setWithAdminEmails(!withAdminEmails)} /> 
                <p>inkl. Allimmo Team Adressen</p>
            </div>
            <p onClick={getEmails} className='cursor-pointer px-3 py-1 rounded border border-allimmoDark hover:bg-allimmoDark/10 text-allimmoDark'>Emailadressen laden</p>
            <p className='mt-5'>{emalList.length} Adressen gefunden:</p>
            {(emalList.length > 0 && !isLoadingEmails) ?
            <div className='flex flex-col select-text p-2 border border-black rounded-xl'>
                {emalList.map((d, i) => (
                    <p className={`${memeberEmails.includes(d.email) ? 'font-bold text-red-600' : ''}`} key={i}>{d.email}{i === emalList.length - 1 ? "" : ","}</p>
                ))}
            </div> : isLoadingEmails ?
            <CircularProgress size={60}/> : null}
        </Collapse>

        <div onClick={() => extendTool('credits')} className='gap-2 items-center flex flex-row w-200 rounded border border-allimmoDark cursor-pointer hover:bg-allimmoDark/10 px-2'>
            <p>+</p>
            <p>Credits Gutschrift</p>
        </div>
        <Collapse in={extendedTools.credits}>
            <div className='flex flex-col gap-2 mt-2 items-center pb-2'>
                <TextField autoComplete='off' size='small' label='Email-Adresse' value={creditData.email} onChange={(e) => { setInfoText(""); setCreditData({...creditData, email: e.target.value})}} />
                <TextField autoComplete='off' size='small' label='Credits' value={creditData.amount} onChange={(e) => { setInfoText(""); setCreditData({...creditData, amount: e.target.value})}} />
                <div>
                    {infoText === "success" && <p className='text-green-700 text-center'>{addedData.email}</p>}
                    {infoText === "success" && <p className='text-green-500 text-center'>{addedData.amount} Credits gutgeschrieben!</p>}
                </div>
                {infoText === "error" && <p className='text-red-500 text-center -mt-1'>User nicht gefunden!</p>}
                {infoText === "input" && <p className='text-red-500 text-center -mt-1'>Falsche Eingabe!</p>}
                {isLoadingAddCredits && <CircularProgress size={30} />}
                {!isLoadingAddCredits && <p className='underline -mt-2 hover:text-allimmoDark/50 text-allimmoDark cursor-pointer text-center' onClick={() => addUserCredits()}>Gutschreiben</p>}
            </div>
        </Collapse>

        <div onClick={() => extendTool('update')} className='gap-2 items-center flex flex-row w-200 rounded border border-allimmoDark cursor-pointer hover:bg-allimmoDark/10 px-2'>
            <p>+</p>
            <p>Email Senden</p>
        </div>
        <Collapse in={extendedTools.update}>
            <div className='flex flex-col gap-2 mt-2 items-center'>
                <TextField autoComplete='off' size='small' placeholder='d-xxxxxxxxx' label='Sendgrid Template ID' value={templateId} onChange={(e) => { setTemplateId(e.target.value); setUpdateText(""); }} />
                {(templateId.trim().length === 34 && templateId.trim().startsWith("d-") && !updateEmailLoading) && <p className='mt-1 rounded underline hover:text-allimmoDark/50 text-allimmoDark cursor-pointer text-center' onClick={sendUpdateEmaill}>Email Senden</p>}
                {updateEmailLoading && <CircularProgress size={30} />}
                {(updateText !== "" && !updateEmailLoading) && <p className='text-center italic'>{updateText}</p>}
            </div>
        </Collapse>

        <div onClick={() => extendTool('delete')} className='gap-2 items-center flex flex-row w-200 rounded border border-allimmoDark cursor-pointer hover:bg-allimmoDark/10 px-2'>
            <p>+</p>
            <p>User löschen</p>
        </div>
        <Collapse in={extendedTools.delete}>
            <div className='flex flex-col gap-2 mt-2 items-center'>
                <TextField autoComplete='off' size='small' label='Email-Adresse' value={deleteEmail} onChange={(e) => { setDeleteEmail(e.target.value); setDeleteText(""); }} />
                {(validator.isEmail(deleteEmail.trim()) && !deleteLoading) && <p className='mt-1 rounded underline hover:text-allimmoDark/50 text-allimmoDark cursor-pointer text-center' onClick={deleteUser}>User löschen</p>}
                {deleteLoading && <CircularProgress size={30} />}
                {(deleteText !== "" && !deleteLoading) && <p className='text-center italic'>{deleteText}</p>}
            </div>
        </Collapse>

        <div onClick={() => extendTool('createUser')} className='gap-2 items-center flex flex-row w-200 rounded border border-allimmoDark cursor-pointer hover:bg-allimmoDark/10 px-2'>
            <p>+</p>
            <p>User erstellen</p>
        </div>
        <Collapse in={extendedTools.createUser}>
            <div className='flex flex-col gap-2 mt-2 items-center'>
                <TextField className='bg-white' autoComplete='off' size='small' label='Email-Adresse' value={createUserData.email} onChange={(e) => setCreateUserData({...createUserData, email: e.target.value})} />
                <TextField className='bg-white' autoComplete='off' size='small' label='Passwort' value={createUserData.password} onChange={(e) => setCreateUserData({...createUserData, password: e.target.value})} />
                <TextField className='bg-white' autoComplete='off' size='small' label='Name' value={createUserData.name} onChange={(e) => setCreateUserData({...createUserData, name: e.target.value})} />
                <TextField className='bg-white' autoComplete='off' size='small' label='Telefonnummer' value={createUserData.phone} onChange={(e) => setCreateUserData({...createUserData, phone: e.target.value})} />
                <TextField className='mt-2 bg-white' autoComplete='off' size='small' label='Credits' value={createUserData.credits} onChange={(e) => setCreateUserData({...createUserData, credits: e.target.value})} />
                <Button text='User erstellen' clickFunction={createUserAdmin} />
            </div>
        </Collapse>
    </div>
  )
}

export default Admin