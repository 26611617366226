import { CircularProgress, Dialog } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { collection, getDocs, where } from 'firebase/firestore';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import Username from '../components/Account/Username';
import Button from '../components/Generics/Button';
import AnimationIcon from "../components/Generics/AnimationIcon.jsx";
import { auth, firestore } from '../firebase.config';
import { Transition, convertShortTimestamp } from '../helper/functions';
import { useStateContext } from '../contexts/ContextProvider';

const Account = () => {

  const { customer } = useStateContext();

  const [paymentsOpen, setPaymentsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if(emailSent) {
      setTimeout(() => {
        setEmailSent(false);
      }, "5000");
    }
    // eslint-disable-next-line
  }, [emailSent]);
  

  const getPayments = async () => {
    setPaymentsOpen(true);
    setIsLoading(true);
    const querySnapshot = await getDocs(collection(firestore, "payments"), where("client_reference_id", "==", auth.currentUser.uid));
    setPayments(querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id })));
    setIsLoading(false);
  };

  const sendPasswordReset = () => {
    setIsResetLoading(true);
    sendPasswordResetEmail(auth, auth.currentUser.email)
    .then(() => {
      setEmailSent(true);
      setIsResetLoading(false);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
  };

  return (
    <div className={`flex flex-col items-center w-screen mt-26`}>
        <div className={`border-allimmoDark rounded-full h-32 aspect-square flex flex-row justify-center items-center`}>
            <AnimationIcon _iconSize={150} _color={false} iconSrc={require('../assets/animations/avatar.json')}/>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <Username />
          {customer?.company && <p className='text-14 italic -mt-1.5'>{customer?.company ? "- Firmenkonto -" : ""}</p>}
          <p className='text-lg italic mt-5'>{auth.currentUser.email}</p>
          {(customer?.phone && customer?.phone !== "") && <p className='text-14'>{customer?.phone}</p>}
        </div>
        <p className='text-sm'>{`${t('General.erstellt')} am ${convertShortTimestamp(auth.currentUser.metadata.creationTime)}`}</p>
        <p className='italic text-11 select-text text-slate-400'>{`id: ${auth.currentUser.uid}`}</p>

        <div className='flex flex-row justify-center items-center space-x-2 mt-10'>
            <Button clickFunction={getPayments} text={t('Account.ZahlungenAnsehen')}/>
            {/* {auth.currentUser.providerData[0].providerId !== "password" && <Button text={"TEXT"} clickFunction={sendPasswordReset} loading={isResetLoading || emailSent}/>} */}
        </div>

        <p className='text-allimmoDark italic text-sm text-center px-2 sm:px-5 mt-3'>{t('Account.Löschen')}</p>

        {(emailSent && !isResetLoading) &&
        <div className='flex flex-col justify-center items-center'>
          <p className='text-green-500 text-14 mt-5'>{t('Account.ResetEmailSent')}</p>
          <p className='text-green-500 text-14'>{auth.currentUser.email}</p>
        </div>}

        <Dialog open={paymentsOpen} fullWidth={true} TransitionComponent={Transition}>
          <div className='p-2 ssm:p-5 flex flex-col justify-center items-center'>
            <p className='mb-5 text-lg ssm:text-xl'>{t('Account.MeineZahlungen')}</p>
            {isLoading ? <CircularProgress size={50} /> :
            payments.length === 0 ? 
            <p className='mb-5'>{t('Account.KeineZahlungen')}</p> :
            payments.map((p,i) => {
              return (
                <div key={i} className='flex flex-row items-center bg-slate-50 px-2 py-0.5 w-full space-x-3 ssm:space-x-5 text-xs ssm:text-base'>
                  <p>{i + 1}.</p>
                  <p className='grow'>{`${(p.amount_total/100).toFixed(2)}€ (+${p.credits} Credits)`}</p>
                  <p className='text-end'>{new Date(p.created * 1000).toLocaleString()}</p>
                </div>
              )
            })}
            <div className='mt-5'>
              <Button text="OK" clickFunction={() => setPaymentsOpen(false)}/>
            </div>
          </div>
        </Dialog>
    </div>
  )
};

export default Account
