import { CircularProgress, Collapse } from '@mui/material';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import Popup from '../components/Generics/Popup';
import { useObjectsContext } from '../components/Object/Contexts/ObjectsProvider';
import AddMissingData from '../components/Popups/AddMissingData';
import Assignments from '../components/Popups/Assignments';
import CompleteleyDeleteObjectConfirm from '../components/Popups/CompleteleyDeleteObjectConfirm';
import { useStateContext } from '../contexts/ContextProvider';
import { useCreationContext } from '../contexts/CreationProvider';
import { useDatabaseCountsContext } from '../contexts/DatabaseCountsProvider';
import { useDeletionContext } from '../contexts/DeletionProvider';
import { usePopupContext } from '../contexts/PopupProvider';
import { useSelectionContext } from '../contexts/SelectionProvider';
import { auth, firestore } from '../firebase.config';
import { objectDeleteCompleteAdmin } from '../helper/functions';
import { AddObject, Apartment, ArrowRight, BuildingHouse, Calender, CheckmarkCircle, CircleIcon, CommercialObject, Delete, Dot, Filter, GearThin, Hourglass, House, Land, Mail, MissingParams, Offers, Prospects, Rent, Sale, Sofa, Sort, Stopwatch, Sun } from '../helper/icons';
import ObjectDetails from './ObjectDetails';

const Objects = () => {

    const initialFilter = {
        active: "all",
        type: "all",
        deal: "all",
        match: "all",
        assignment: "all",
        missing: "all",
        facilities: "all",
        furnished: "all",
        openAreas: "all",
        contacted: "all",
    };

  const { handleMenuState, screenSize, objectsContacts } = useStateContext();
  const { customerObjects, setCustomerObjects } = useObjectsContext();
  const { resetObjectData } = useCreationContext();
  const { setSelectedObjectID } = useSelectionContext();
  const { setObjectDetailsOpen } = usePopupContext();
  const { customerObjectsCount} = useDatabaseCountsContext();

  const [assignmentsOpen, setAssignmentsOpen] = useState(false);
  const [filterExtended, setFilterExtended] = useState(false);
  const [sortExtended, setSortExtended] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = useState(["match", "active"]);
  const [dataLoading, setDataLoading] = useState(false);
  const [pagination, setPagination] = useState({
    perPage: 10,
    actualPage: 1,
  });

  useEffect(() => {
    setDataLoading(true);

    const objectsQuery = query(collection(firestore, "objects"), where("customer", "==", auth.currentUser.uid), where("deleted", "==", false));
    const unsubObjectData = onSnapshot(objectsQuery, (result) => {
      setCustomerObjects(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
      setDataLoading(false);
    });
  
    return () => { 
      unsubObjectData();
    }
  }, [pagination.actualPage]);
  

  
  const handleNewObject = () => {
    resetObjectData();
    handleMenuState("newObject");
  };

  const handleOpenAssignments = () => {
    setAssignmentsOpen(true);
  };

  const handleObjectOpen = (data) => {
    setSelectedObjectID(data.id);
    setObjectDetailsOpen(true);
  };

  const changeFilter = (type, value) => {
    const filterValue = value === "true" ? true : value === "false" ? false : value;
    setFilter({...filter, [type]: filterValue});
  };

  const handleFilterOptionsOpen = () => {
    if (sortExtended) setSortExtended(false);
    setFilterExtended(!filterExtended);
  };

  const handleSortOptionsOpen = () => {
    if (filterExtended) setFilterExtended(false);
    setSortExtended(!sortExtended);
  };

  const handleSortChange = (param) => {
    setSort([param]);
  };

  const assignments = customerObjects?.reduce((acc, obj) => {
    if (Array.isArray(obj.assignments)) {
      return acc.concat(obj.assignments);
    }
    return acc;
  }, []);

  const assignmentsArray = new Set(assignments);

  const filteredObjects = customerObjects?.sort(
    (a, b) => {
        if (sort.includes("match")) {
            if (a?.matches?.length < b?.matches?.length) return 1;
            if (a?.matches?.length > b?.matches?.length) return -1;
        }

        if (sort.includes("active")) {
            if (a?.active && !b?.active) return -1;
            if (!a?.active && b?.active) return 1;
        }

        if (sort.includes("type")) {
            return a?.type?.localeCompare(b?.type);
            // if (a.type < b.type) return -1;
            // if (a.type > b.type) return 1;
        }

        if (sort.includes("deal")) {
            return a?.deal?.localeCompare(b?.deal);
            // if (a.deal < b.deal) return -1;
            // if (a.deal > b.deal) return 1;
        }

        if (sort.includes("date")) {
            return a?.created - b?.created;
            // if (a.created - b.created) return -1;
            // if (a.created - b.created) return 1;
        }
        
        return 0;
        }        
  )?.filter((o) => {
    return (filter.type === "all" ? o : o.type === filter.type) && 
           (filter.active === "all" ? o : o.active === filter.active) && 
           (filter.deal === "all" ? o : o.deal === filter.deal) &&
           (filter.match === "all" ? o : o.matches.length > 0 === filter.match) &&
           (filter.assignment === "all" ? o : o?.assignments?.includes(filter.assignment)) &&
           (filter.missing === "all" ? o : o?.missingParams?.length > 0 === filter.missing) &&
           (filter.facilities === "all" ? o : o?.[filter.facilities] === true) &&
           (filter.furnished === "all" ? o : o?.furnished === filter.furnished) &&
           (filter.openAreas === "all" ? o : o?.openAreas?.length > 0 === filter.openAreas) &&
           (filter.contacted === "all" ? o : objectsContacts.filter(c => c.object === o.id && c.status === filter.contacted).some(c => c.object === o.id));
  });

  useEffect(() => {
    setPagination({
        ...pagination, 
        actualPage: 1,
        pages: Math.floor(customerObjectsCount / 10) + (customerObjectsCount % 10 > 0 ? 1 : 0)
    })
  }, [filter]);

  const handlePagination = (type) => {
    if (type === "next") {
        if (pagination.actualPage < pagination.pages) {
            setPagination({...pagination, actualPage: pagination.actualPage + 1});
        }
    } else {
        if (pagination.actualPage > 1) {
            setPagination({...pagination, actualPage: pagination.actualPage - 1});
        }
    }
  };

  return (
        <div className='w-full md:w-2/3 flex flex-col items-center px-1 sm:px-3 py-5'>
             {/* Objects Menu */}
            {(customerObjectsCount > 0 || !dataLoading) &&
            <div className='flex flex-col items-center mb-5 gap-1'>
                <AddObject onClick={handleNewObject} className='text-allimmoDark cursor-pointer hover:text-sky-700 active:text-allimmoDark' size={30} />
                <div className='flex flex-row items-center w-full'>
                        {filteredObjects?.length > 0 && (filteredObjects.length !== customerObjectsCount) && <p>{`${filteredObjects.length}/`}</p>}
                        {filteredObjects?.length > 0 && <p className='font-bold'>{`${customerObjectsCount.toLocaleString()} ${customerObjectsCount > 1 ? t('General.Objekte') : t('General.Objekt')}`}</p>}
                </div>
                {/* <AddAssignment onClick={handleOpenAssignments} className='text-allimmoDark mb-5 cursor-pointer hover:text-sky-700 active:text-allimmoDark' size={30} /> */}
            </div>}
            {customerObjectsCount > 0 &&
            <div className='w-full flex flex-row items-center justify-between border-b border-allimmoDark/40 mb-3 pb-2 gap-1'>
                {/* Suchfeld */}
                {/* <div className='flex flex-row gap-3 border rounded border-allimmoDark px-1.5 py-0.5 items-center'>
                    <FaMagnifyingGlass className='text-allimmoDark cursor-pointer hover:text-sky-700 active:text-allimmoDark' size={15} />
                    <input placeholder='Suche...' className='outline-none'/>
                </div> */}
                <div className='flex flex-col items-center w-full'>
                    {filteredObjects.length > 10 &&
                    <div className='flex flex-row items-center w-full gap-2'>
                        <ArrowRight onClick={() => handlePagination("back")} className={`${pagination.actualPage === 1 ? "opacity-30" : "hover:text-sky-700 active:text-allimmoDark/70 cursor-pointer "} text-allimmoDark rotate-180`} size={18} />
                        <p>{`Seite ${pagination.actualPage} ${t('General.Von')} ${Math.floor(filteredObjects?.length / 10) + (filteredObjects.length % 10 > 0 ? 1 : 0)}`}</p>
                        <ArrowRight onClick={() => handlePagination("next")} className={`${pagination.actualPage === pagination.pages ? "opacity-30" : "hover:text-sky-700 active:text-allimmoDark/70 cursor-pointer "} text-allimmoDark`} size={18} />
                        <GearThin className={`${dataLoading ? "" : "text-transparent"} animate-spin ml-2`} size={20} />
                    </div>}
                </div>
                   
                <div className='flex flex-row gap-1 items-center justify-end'>
                    {Object.values(filter).some(f => f !== "all") && <p onClick={() => setFilter(initialFilter)} className='text-green-500 underline cursor-pointer hover:text-green-300 active:text-green-500 text-xs italic'>Filter Löschen</p>}
                    <Filter onClick={handleFilterOptionsOpen} className={`${Object.values(filter).some((f) => f !== "all") ? "text-green-500" : filterExtended ? "text-allimmoDark" : "text-allimmoDark/50"} cursor-pointer hover:text-sky-700 active:text-allimmoDark`} size={20} />
                    <Sort onClick={handleSortOptionsOpen} className={`${sortExtended ? "text-orange-500" : "text-orange-300"} cursor-pointer hover:text-orange-300 active:text-orange-500`} size={29} />
                </div>
            </div>}
            <Collapse in={filterExtended} className='w-full'>
                <FilterBar changeFilter={changeFilter} assignments={[...assignmentsArray]} activeFilter={filter} resetFilter={() => setFilter(initialFilter)}/>
            </Collapse>

            <Collapse in={sortExtended} className='w-full'>
                <SortBar activeSort={sort} changeSort={handleSortChange} />
            </Collapse>

            {(!dataLoading && customerObjectsCount === 0) &&
            <div className='flex flex-col justify-center items-center mt-10'>
                <p className='text-lg'>{t('Objects.KeineObjekte')}</p>
            </div>}

            {(customerObjectsCount > 0 && filteredObjects?.length === 0 && !dataLoading) &&
            <div className='flex flex-col justify-center items-center mt-10'>
                <p className='text-lg'>{t('Objects.KeineObjekteGefunden')}</p>
            </div>}

            {(customerObjectsCount > 0 && dataLoading) &&
            <div className='flex flex-col justify-center items-center mt-10 gap-2 opacity-70 text-allimmoDark'>
                <p className='text-lg'>{t('Objects.DataLoading')}</p>
                <GearThin className='animate-spin' size={80} />
            </div>}

            {filteredObjects?.length > 0 &&
            <div className='flex flex-col justify-start items-center w-full space-y-1 overflow-y-auto'>
                {filteredObjects?.slice((pagination.actualPage * pagination.perPage) - 10, pagination.perPage * pagination.actualPage)?.map((o, i) => {
                    return (
                        <ObjectObject key={i} data={o} openDetails={() => handleObjectOpen(o)}/>
                    )
                })}
            </div>}

            <Popup 
            header={t('Objects.Assignments.Header')}
            full={screenSize.width > 500 ? false : true}
            showLogo={false}
            openState={assignmentsOpen} 
            content={<Assignments />} 
            close={() => setAssignmentsOpen(false)} />
        </div>
  )
};

const ObjectObject = ({data, openDetails}) => {

    const { setDeletedObject, setObjectDeleteConfirmOpen } = useDeletionContext();
    const { screenSize, adminEmail, objectsContacts } = useStateContext();
    const { objectDetailsOpen, setObjectDetailsOpen } = usePopupContext();

    const [contactObjects, setContactObjects] = useState([]); // the contacts
    const [isLoading, setIsLoading] = useState(false);
    const [completelyDeleteConfirmOpen, setCompletelyDeleteConfirmOpen] = useState(false);

    const missingData = data?.missingParams?.length > 0;
    const undefinedData = data?.undefinedParams?.length > 0;

    // useEffect(() => {
    //     setIsLoading(true);
    //     const contactsRef = query(collection(firestore, "contacts"), where("object", "==", data.id), where("customer", "==", auth.currentUser.uid));
    //     onSnapshot(contactsRef, (result) => {
    //         setContactObjects(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
    //         setIsLoading(false);
    //     });
    //     // eslint-disable-next-line
    // }, []);

    const objectContactsPending = objectsContacts.filter(c => c.object === data.id && c.status === "pending");
    const objectContactsAccepted = objectsContacts.filter(c => c.object === data.id && c.status === "accepted");
    const objectContactsTimeout = objectsContacts.filter(c => c.object === data.id && c.status === "timeout");

    const handleDelete = (e) => {
        e.stopPropagation();
        setDeletedObject(data.id);
        setObjectDeleteConfirmOpen(true);
    };

    const handleCompleteDeleteConfirmOpen = (e) => {
        e.stopPropagation();
        setCompletelyDeleteConfirmOpen(true);
    };

    const handleAdminDelete = async (e) => {
        setCompletelyDeleteConfirmOpen(false);
        await objectDeleteCompleteAdmin({objectId: data.id})
        .then((res) => {
            //console.log(res.data);
        })
    };

    return (
        <div className='w-full border border-allimmoDark/30 py-1 px-1 sm:px-2 flex flex-col justify-center items-center rounded cursor-pointer hover:border-allimmoDark/80 bg-white'>
            <div onClick={openDetails} className='flex flex-row justify-start items-center w-full'>
                <Dot size={20} className={`cursor-pointer ${data.active ? 'text-green-600' : "text-red-600"}`}/>
                {data.type === "apartment" && <Apartment size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {data.type === "commercial" && <CommercialObject size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {data.type === "house" && <House size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {data.type === "land" && <Land size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {["rent", "lease"].includes(data.deal) && <Rent size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {data.deal === "sale" && <Sale size={18} className={`cursor-pointer text-allimmoDark`}/>}
                <p className='grow font-medium text-allimmoDark ml-1.5 sm:ml-3 text-13 sm:text-14 md:text-16'>{`${data.address.streetNumber === "missing" ? t('MissingData.streetNumber') : data.address.streetNumber}${(screenSize.width > 390 && data.address.streetNumber !== "missing") ? ", " + data.address.cityZip : ''}`}</p>
                {isLoading ?
                <CircularProgress size={18} /> :
                <div className='flex flex-row justify-start items-center mr-2 sm:mr-5'>
                    <Prospects size={18} className={`text-allimmoDark`}/>
                    <p className='sm:ml-1'>{`${data.matches?.filter(m => m !== "123").length}`}</p>
                </div>}
                <Delete size={20} className='text-red-400 cursor-pointer hover:text-red-600' onClick={(e) => handleDelete(e)}/>
                {auth.currentUser.email === adminEmail &&
                <Delete size={20} className='text-blue-400 cursor-pointer hover:text-blue-600' onClick={handleCompleteDeleteConfirmOpen}/>}
            </div>
            
            <div className='flex flex-row items-center w-full gap-3 pl-2 mb-1.5 mt-0.5'>
                <p className='text-sm'>{`Kontakte:`}</p>
                <div className='flex flex-row items-center gap-3 rounded px-1 border bg-allimmoDark/5'>
                    <div className='flex flex-row justify-start items-center'>
                        <CheckmarkCircle size={18} className={`text-green-500`}/>
                        <p className='sm:ml-1 text-sm'>{`${objectContactsAccepted.length}`}</p>
                    </div>

                    <div className='flex flex-row justify-start items-center'>
                        <Hourglass size={18} className={`text-orange-500 animate-pulse`}/>
                        <p className='sm:ml-1 text-sm'>{`${objectContactsPending.length}`}</p>
                    </div>

                    <div className='flex flex-row justify-start items-center'>
                        <Stopwatch size={16} className={`text-red-500`}/>
                        <p className='sm:ml-1 text-sm'>{`${objectContactsTimeout.length}`}</p>
                    </div>
                </div>
            </div>

            <div className='flex flex-row items-center justify-between w-full text-xs gap-3 px-1'>
                {(missingData || (undefinedData && data.ignoreUndefined !== true)) &&
                <div className='flex flex-row items-center text-xs gap-3 px-1'>
                    <p>{`${t('MissingData.Label')}:`}</p>
                    <IncompleteDataInfo objectData={data} missingType="missing" animate color="red" />
                    {data.ignoreUndefined !== true && 
                    <IncompleteDataInfo objectData={data} missingType="undefined" color="blue" />}
                </div>}
                {![undefined, ""].includes(data?.interface?.objectnrExtern) && 
                <p className='text-xs italic text-end select-text cursor-text'>{`_id: ${data?.interface?.objectnrExtern}`}</p>}
            </div>

            <Popup 
            header={data.name} 
            openState={objectDetailsOpen} 
            content={<ObjectDetails />} 
            close={() => setObjectDetailsOpen(false)} />
            
            <Popup
            full={false}
            showLogo={false}
            header="Wirklich Endgültig Löschen?" 
            openState={completelyDeleteConfirmOpen} 
            content={<CompleteleyDeleteObjectConfirm confirm={handleAdminDelete} close={() => setCompletelyDeleteConfirmOpen(false)}/>} 
             />
        </div>
    )
};

const FilterBar = ({changeFilter, assignments, activeFilter}) => {

    const facilityOptions = ["airCondition", "kitchen", "sauna", "floorHeating", "bathtub", "cellar", "elevator", "pets", "laundry", "bike", "blinds", "storageRoom"];
    const contactedOptions = ["pending", "accepted", "timeout"];
    
    return (
        <div className={`flex flex-col items-end gap-1 mb-3 w-full`}>
            
            <div className={`flex flex-col mmd:flex-row items-end mmd:justify-end gap-1 w-full`}>
                <div className={`flex flex-row items-center gap-1`}>
                    <div className={`h-16 gap-1 flex flex-col shadow-lg rounded border border-dashed p-2 ${activeFilter.active !== "all" ? "border-green-500 bg-green-50" : "border-allimmoDark bg-white"} hover:border-opacity-50`}>
                        <div className={`flex flex-row gap-2 items-center ${activeFilter.active !== "all" ? "text-green-500" : "text-allimmoDark"}`}>
                            <CircleIcon size={16}/>
                            <p className='text-sm'>Aktiv</p>
                        </div>
                        <select value={activeFilter.active} className='outline-none bg-transparent cursor-pointer' onChange={(e) => changeFilter("active", e.target.value)}>
                            <option value='all'>Alle</option>
                            <option value={true}>Aktiv</option>
                            <option value={false}>Inaktiv</option>
                        </select>
                    </div>

                    <div className={`h-16 gap-1 flex flex-col shadow-lg rounded border border-dashed p-2 border-allimmoDark ${activeFilter.contacted !== "all" ? "border-green-500 bg-green-50" : "border-allimmoDark bg-white"} hover:border-opacity-50`}>
                        <div className={`flex flex-row gap-2 items-center ${activeFilter.contacted !== "all" ? "text-green-500" : "text-allimmoDark"}`}>
                            <Mail size={18}/>
                            <p className='text-sm'>Kontaktiert</p>
                        </div>
                        <select value={activeFilter.contacted} className='outline-none bg-transparent cursor-pointer' onChange={(e) => changeFilter("contacted", e.target.value)}>
                            <option value='all'>Alle</option>
                            {contactedOptions.map((f, i) => {
                                return <option key={i} value={f}>{t(`Contacts.${f}`)}</option>
                            })}
                        </select>
                    </div>

                    {/* <div className={`h-16 gap-1 flex flex-col shadow-lg rounded border border-dashed p-2 border-allimmoDark ${activeFilter.facilities !== "all" ? "border-green-500 bg-green-50" : "border-allimmoDark bg-white"} hover:border-opacity-50`}>
                        <div className={`flex flex-row gap-2 items-center ${activeFilter.facilities !== "all" ? "text-green-500" : "text-allimmoDark"}`}>
                            <Facilities size={18}/>
                            <p className='text-sm'>Ausstattung</p>
                        </div>
                        <select value={activeFilter.facilities} className='outline-none bg-transparent cursor-pointer' onChange={(e) => changeFilter("facilities", e.target.value)}>
                            <option value='all'>Alle</option>
                            {facilityOptions.map((f, i) => {
                                return <option key={i} value={f}>{t(`Facilities.${f}`)}</option>
                            })}
                        </select>
                    </div> */}
                </div>

                <div className={`flex flex-row items-center gap-1`}>
                    <div className={`h-16 gap-1 flex flex-col shadow-lg rounded border border-dashed p-2 ${activeFilter.deal !== "all" ? "border-green-500 bg-green-50" : "border-allimmoDark bg-white"} hover:border-opacity-50`}>
                        <div className={`flex flex-row gap-2 items-center ${activeFilter.deal !== "all" ? "text-green-500" : "text-allimmoDark"}`}>
                            <Offers size={18}/>
                            <p className='text-sm'>Angebotsart</p>
                        </div>
                        <select value={activeFilter.deal} className='outline-none bg-transparent cursor-pointer' onChange={(e) => changeFilter("deal", e.target.value)}>
                            <option value='all'>Alle</option>
                            <option value='rent'>Miete</option>
                            <option value='sale'>Kauf</option>
                            <option value='lease'>Pacht</option>
                        </select>
                    </div>

                    <div className={`h-16 gap-1 flex flex-col shadow-lg rounded border border-dashed p-2 border-allimmoDark ${activeFilter.openAreas !== "all" ? "border-green-500 bg-green-50" : "border-allimmoDark bg-white"} hover:border-opacity-50`}>
                        <div className={`flex flex-row gap-2 items-center ${activeFilter.openAreas !== "all" ? "text-green-500" : "text-allimmoDark"}`}>
                            <Sun size={18}/>
                            <p className='text-sm'>Freiflächen</p>
                        </div>
                        <select value={activeFilter.openAreas} className='outline-none bg-transparent cursor-pointer' onChange={(e) => changeFilter("openAreas", e.target.value)}>
                            <option value='all'>Alle</option>
                            <option value={true}>Mit Freifläche</option>
                            <option value={false}>Ohne Freifläche</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className={`flex flex-col mmd:flex-row items-end mmd:justify-end gap-1 w-full`}>
                <div className={`flex flex-row items-center gap-1`}>
                    <div className={`h-16 gap-1 flex flex-col shadow-lg rounded border border-dashed p-2 border-allimmoDark ${activeFilter.missing !== "all" ? "border-green-500 bg-green-50" : "border-allimmoDark bg-white"} hover:border-opacity-50`}>
                        <div className={`flex flex-row gap-2 items-center ${activeFilter.missing !== "all" ? "text-green-500" : "text-allimmoDark"}`}>
                            <MissingParams size={18}/>
                            <p className='text-sm'>Daten Fehlen</p>
                        </div>
                        <select value={activeFilter.missing} className='outline-none bg-transparent cursor-pointer' onChange={(e) => changeFilter("missing", e.target.value)}>
                            <option value='all'>Alle</option>
                            <option value={true}>Daten Fehlen</option>
                            <option value={false}>Daten Vollständig</option>
                        </select>
                    </div>

                    <div className={`h-16 gap-1 flex flex-col shadow-lg rounded border border-dashed p-2 ${activeFilter.type !== "all" ? "border-green-500 bg-green-50" : "border-allimmoDark bg-white"} hover:border-opacity-50`}>
                        <div className={`flex flex-row gap-2 items-center ${activeFilter.type !== "all" ? "text-green-500" : "text-allimmoDark"}`}>
                            <BuildingHouse size={18}/>
                            <p className='text-sm'>Objekttyp</p>
                        </div>
                        <select value={activeFilter.type} className='outline-none bg-transparent cursor-pointer' onChange={(e) => changeFilter("type", e.target.value)}>
                            <option value='all'>Alle</option>
                            <option value='apartment'>Wohnung</option>
                            <option value='house'>Haus</option>
                            <option value='commercial'>Gewerbe</option>
                            <option value='land'>Grundstück</option>
                            <option value='multi'>Gebäude</option>
                        </select>
                    </div>

                    {/* {assignments.length > 1 &&
                <div className='gap-1 flex flex-row shadow-lg items-center rounded border border-dashed p-2 border-allimmoDark h-8 cursor-pointer hover:opacity-50'>
                    <Flag className='text-allimmoDark' size={20}/>
                    <p className='text-allimmoDark text-sm'>Zuordnung</p>
                    <select className='outline-none' onChange={(e) => changeFilter("assignment", e.target.value)}>
                        <option value='all'>Alle</option>
                        {assignments.map((a, i) => {
                            return <option key={i} value={a}>{a}</option>
                        })}
                    </select>
                </div>} */}
                    
                </div>
                <div className={`flex flex-row items-center gap-1`}>
                    <div className={`h-16 gap-1 flex flex-col shadow-lg rounded border border-dashed p-2 border-allimmoDark ${activeFilter.match !== "all" ? "border-green-500 bg-green-50" : "border-allimmoDark bg-white"} hover:border-opacity-50`}>
                        <div className={`flex flex-row gap-2 items-center ${activeFilter.match !== "all" ? "text-green-500" : "text-allimmoDark"}`}>
                            <Prospects size={18}/>
                            <p className='text-sm'>Matches</p>
                        </div>
                        <select value={activeFilter.match} className='outline-none bg-transparent cursor-pointer' onChange={(e) => changeFilter("match", e.target.value)}>
                            <option value='all'>Alle</option>
                            <option value={true}>mit Matches</option>
                            <option value={false}>ohne Matches</option>
                        </select>
                    </div>

                    <div className={`h-16 gap-1 flex flex-col shadow-lg rounded border border-dashed p-2 border-allimmoDark ${activeFilter.furnished !== "all" ? "border-green-500 bg-green-50" : "border-allimmoDark bg-white"} hover:border-opacity-50`}>
                        <div className={`flex flex-row gap-2 items-center ${activeFilter.furnished !== "all" ? "text-green-500" : "text-allimmoDark"}`}>
                            <Sofa size={18}/>
                            <p className='text-sm'>Möblierung</p>
                        </div>
                        <select value={activeFilter.furnished} className='outline-none bg-transparent cursor-pointer' onChange={(e) => changeFilter("furnished", e.target.value)}>
                            <option value='all'>Alle</option>
                            <option value='furnished'>Voll Möbliert</option>
                            <option value='partFurnished'>Teil Möbliert</option>
                            <option value='-'>Nicht Möbliert</option>
                        </select>
                    </div>                
                </div>
            </div>
        </div>
    )
};

const SortBar = ({activeSort, changeSort}) => {

    const activeColor = "green-500";
    const inactiveColor = "orange-500";

    const SortElement = ({icon, elementName, label}) => {
        return (
            <div onClick={() => changeSort(elementName)} className={`gap-1 flex flex-row shadow-lg items-center rounded border border-dashed ${"border-" + inactiveColor} p-2 ${activeSort.includes(elementName) ? "opacity-100" : "opacity-40 hover:opacity-100"} h-8 cursor-pointer`}>
                <div className={`${activeSort.includes(elementName) ? "text-" + inactiveColor : "text-" + inactiveColor}`}>
                    {icon}
                </div>
                <p className={`${activeSort.includes(elementName) ? "text-" + inactiveColor : "text-" + inactiveColor} text-sm`}>{label}</p>
            </div>
        )
    };

    return (
        <div className='flex flex-col mmd:flex-row items-end justify-end gap-2 mb-3 w-full'>
            <div className='flex flex-row items-center gap-2'>
                <SortElement label="Aktiv" elementName="active" icon={<CircleIcon size={20}/>} />
                <SortElement label="Objekttyp" elementName="type" icon={<BuildingHouse size={20}/>} />
                <SortElement label="Matches" elementName="match" icon={<Prospects size={20}/>} />
            </div>
            <div className='flex flex-row items-center gap-2'>
                <SortElement label="Erstellunsdatum" elementName="date" icon={<Calender size={20}/>} />
                <SortElement label="Angebotsart" elementName="deal" icon={<Offers size={20}/>} />
            </div>
        </div>
    )
};

const IncompleteDataInfo = ({objectData, missingType, color, animate = false}) => {

    const [addDataOpen, setAddDataOpen] = useState(false);

    const { screenSize } = useStateContext();

    const clickHandler = (e) => {
        e.stopPropagation();
        setAddDataOpen(true);
    };

    const incompleteDataArrayName = missingType + "Params";

    return (
        <div className={`${objectData[incompleteDataArrayName]?.length > 0 ? "block" : "hidden"}`}>
            <p onClick={(e) => clickHandler(e)} className={`${animate ? "animate-pulse" : ""} text-${color}-400 rounded-sm bg-${color}-100 px-1 text-center`}>{`${t(`MissingData.${missingType}`)}: ${objectData[incompleteDataArrayName]?.length}`}</p>
            <Popup
            content={<AddMissingData close={() => setAddDataOpen(false)} obj={objectData} arrayName={incompleteDataArrayName}/>}
            full={screenSize.width < 500 ? true : false}
            header={t('MissingData.AddMissingDataPopup')}
            openState={addDataOpen}
            showLogo={false}
            />
        </div>
    )
};

export default Objects
