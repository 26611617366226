import { t } from 'i18next'
import { useStateContext } from '../../contexts/ContextProvider'
import Button from '../Generics/Button';
import { useEffect, useState } from 'react';
import Prospect from './Prospect';
import { contactMatched } from '../../helper/functions';
import { collection, documentId, onSnapshot, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase.config';

const Matches = ({ objectData }) => {

    const { customer, handleMenuState } = useStateContext();

    const [isLoading, setIsLoading] = useState(false);
    const [selection, setSelection] = useState([]);
    const [contactDisabled, setContactDisabled] = useState(false);
    const [activeContactsLoading, setActiveContactsLoading] = useState(false);
    const [matchingSearches, setMatchingSearches] = useState([]);
    const [processingMatches, setProcessingMatches] = useState([]);

    const matches = matchingSearches.filter(m => m !== "123");

    useEffect(() => {
        const prospectsRef = query(collection(firestore, "searches"), where(documentId(), "in", [...objectData?.matches, "123"]));
        onSnapshot(prospectsRef, (result) => {
            setMatchingSearches(result.docs.map((doc) => ({...doc.data(), id: doc.id})));
        });
        // eslint-disable-next-line
    }, [objectData]);

    const contact = async () => {
        if(selection.length > 0 && selection.length <= customer.credits) {
            setProcessingMatches([...processingMatches, ...selection]);
            setContactDisabled(true);

            setActiveContactsLoading(true);
            setIsLoading(true);
            await contactMatched({objectId: objectData?.id, selectionArray: selection})
            .then(response => {
                setContactDisabled(false);
                setIsLoading(false);
                setProcessingMatches([]);
            })
            setSelection([]);
        }
    };

    const handleSelection = (item) => {
        if(selection.find(el => el === item)) {
            setSelection(selection.filter(el => el !== item));
        } else {
            setSelection([...selection, item]);
        }
    };

  return (
    <div className='flex flex-col items-center justify-center w-full mt-2 mb-1'>
        {matches.length > 0 && 
        <>
            <div className='flex flex-row items-center w-full'>
                <p className='text-14 grow'>{`${t('ObjectDetails.InteressentenHeader')} ${matches.length}`}</p>
            </div>
            <div className='flex flex-col items-center w-full border rounded border-allimmoDark max-h-56 overflow-auto p-1'>
                {matches.filter(m => !processingMatches.includes(m)).sort((a,b) => a?.created.toDate() - b?.created.toDate()).map((p, i) => {
                return (
                    <Prospect key={i} data={p} select={handleSelection} selection={selection} index={i}/>
                )
                })}
                 <p className='italic mt-1 text-xs w-full text-allimmoDark/70'>{t('ObjectDetails.CreditsInfo')}</p>
            </div>
            <div className='flex flex-col ssm:flex-row ssm:items-center w-full mt-1'>
                <Button loading={isLoading} isDisabled={selection.length === 0 || selection.length > customer.credits || contactDisabled} clickFunction={contact} text={`${t('ObjectDetails.Kontaktieren')} (${selection.length})`}/>
                <div className='flex flex-row ssm:justify-end items-center grow'>
                    <p className='text-allimmoDark mr-1'>{`${t('ObjectDetails.Kosten')}:`}</p>
                    <p className='text-allimmoDark'>{`${selection.length}`}</p>
                    <p className='text-allimmoDark'>{`/`}</p>
                    <p className={`text-allimmoDark mr-1`}>{`${customer.credits.toLocaleString()} Credits`}</p>
                    {customer?.credits <= 2 && <img onClick={() => handleMenuState("shop")} width={30} src={require('../../assets/cart.gif')} alt=""/>}
                </div>
            </div>
        </>}
    </div>
  )
}

export default Matches
