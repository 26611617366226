import { useState } from 'react';
import Attic from '../components/Searches/Creation/Attic';
import Condition from '../components/Searches/Creation/Condition';
import Deal from '../components/Searches/Creation/Deal';
import Facilities from '../components/Searches/Creation/Facilities';
import Furnished from '../components/Searches/Creation/Furnished';
import GroundFloor from '../components/Searches/Creation/GroundFloor';
import Heating from '../components/Searches/Creation/Heating';
import HeatingMulti from '../components/Searches/Creation/HeatingMulti';
import Isolated from '../components/Searches/Creation/Isolated';
import Land from '../components/Searches/Creation/Land';
import Name from '../components/Searches/Creation/Name';
import OpenAreaSize from '../components/Searches/Creation/OpenAreaSize';
import OpenAreaType from '../components/Searches/Creation/OpenAreaType';
import OpenAreas from '../components/Searches/Creation/OpenAreas';
import OtherInfos from '../components/Searches/Creation/OtherInfos';
import Price from '../components/Searches/Creation/Price';
import Rooms from '../components/Searches/Creation/Rooms';
import SearchMap from '../components/Searches/Creation/SearchMap';
import Size from '../components/Searches/Creation/Size';
import Style from '../components/Searches/Creation/Style';
import Summary from '../components/Searches/Creation/Summary';
import Type from '../components/Searches/Creation/Type';
import Units from '../components/Searches/Creation/Units';
import Urgency from '../components/Searches/Creation/Urgency';
import Vacancy from '../components/Searches/Creation/Vacancy';
import VacancyRatio from '../components/Searches/Creation/VacancyRatio';
import { useStateContext } from '../contexts/ContextProvider';
import { useCreationContext } from '../contexts/CreationProvider';
import { useLoadingContext } from "../contexts/LoadingProvider";
import { searchCreation } from '../helper/functions';
import RenovationRatio from '../components/Searches/Creation/RenovationRatio';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommercialSearchType from '../components/Searches/Creation/CommercialSearchType';
import SearchLicensing from '../components/Searches/Creation/SearchLicensing';
import PresentIndustrySearch from '../components/Searches/Creation/PresentIndustrySearch';
import Floor from '../components/Searches/Creation/Floor';
import SquarePrice from '../components/Searches/Creation/SquarePrice';

const NewSearch = () => {

  const { searchData } = useCreationContext();
  const { handleMenuState } = useStateContext();
  const { setIsCreating } = useLoadingContext();

  const [stage, setStage] = useState("urgency");

  const createSearchRoute = [
    {
      id: 1,
      stage: "urgency",
      deal: "all",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 2,
      stage: "name",
      deal: "all",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 3,
      stage: "type",
      deal: "all",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 4,
      stage: "presentIndustry",
      deal: "all",
      usage: ["commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 5,
      stage: "licensing",
      deal: "all",
      usage: ["commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 6,
      stage: "commercialType",
      deal: "all",
      usage: ["commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 7,
      stage: "deal",
      deal: "all",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 8,
      stage: "isolated",
      deal: "all",
      usage: ["house"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 9,
      deal: "all",
      stage: "location",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 10,
      deal: "all",
      stage: "style",
      usage: ["multi", "apartment", "commercial"],
      commercialUsage: ["apartment", "building", "salesroom"],
      areaUsage: "all"
    },
    {
      id: 11,
      stage: "condition",
      deal: "all",
      usage: ["multi", "apartment", "house", "commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 12,
      deal: "all",
      stage: "price",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 13,
      deal: "all",
      stage: "size",
      usage: ["house", "apartment", "multi", "commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 14,
      deal: "all",
      stage: "land",
      usage: ["house", "land", "multi", "commercial"],
      commercialUsage: ["building", "hall"],
      areaUsage: "all"
    },
    {
      id: 100,
      deal: "all",
      stage: "squarePrice",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 15,
      deal: "all",
      stage: "rooms",
      usage: ["apartment", "commercial"],
      commercialUsage: ["apartment", "salesroom"],
      areaUsage: "all"
    },
    {
      id: 16,
      deal: "all",
      stage: "units",
      usage: ["multi", "commercial"],
      commercialUsage: ["building"],
      areaUsage: "all"
    },
    {
      id: 17,
      deal: "sale",
      stage: "vacancyRatio",
      usage: ["multi", "commercial"],
      commercialUsage: ["building", "hall"],
      areaUsage: "all"
    },
    {
      id: 18,
      deal: "all",
      stage: "renovationRatio",
      usage: ["multi", "commercial"],
      commercialUsage: ["building"],
      areaUsage: "all"
    },
    {
      id: 19,
      deal: "all",
      stage: "groundFloor",
      usage: ["apartment", "commercial"],
      commercialUsage: ["apartment"],
      areaUsage: "all"
    },
    {
      id: 20,
      deal: "all",
      stage: "attic",
      usage: ["apartment", "commercial"],
      commercialUsage: ["apartment"],
      areaUsage: "all"
    },
    {
      id: 21,
      deal: "all",
      stage: "floor",
      usage: ["apartment", "commercial"],
      commercialUsage: ["apartment"],
      areaUsage: "all"
    },
    {
      id: 22,
      deal: "all",
      stage: "furnished",
      usage: ["apartment", "commercial", "house"],
      commercialUsage: ["apartment", "salesroom"],
      areaUsage: "all"
    },
    {
      id: 23,
      deal: "all",
      stage: "area",
      usage: ["apartment", "commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 24,
      deal: "all",
      stage: "areaSize",
      usage: ["apartment", "commercial"],
      commercialUsage: "all",
      areaUsage: "yes"
    },
    {
      id: 25,
      deal: "all",
      stage: "areaType",
      usage: ["apartment", "commercial"],
      commercialUsage: "all",
      areaUsage: "yes"
    },
    {
      id: 26,
      deal: "all",
      stage: "other",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 27,
      deal: ["sale"],
      stage: "vacancy",
      usage: ["apartment", "commercial", "house"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 28,
      deal: "all",
      stage: "facilities",
      usage: ["apartment", "commercial", "house", "multi"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 29,
      deal: "all",
      stage: "heating",
      usage: ["apartment", "commercial", "house"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 30,
      deal: "all",
      stage: "heatingMulti",
      usage: ["multi"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 31,
      deal: "all",
      stage: "summary",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },    
  ];

  const nextStage = (stagePropValue) => {
    const actualIndex = createSearchRoute.findIndex(el => el.stage === stage);
    const actualStage = createSearchRoute[actualIndex];

    // Exceptions
    if(actualStage.stage === "groundFloor" && stagePropValue === "yes") return setStage("furnished");
    if(actualStage.stage === "attic" && searchData?.groundfloor === "yes") return setStage("furnished");
    if(actualStage.stage === "attic" && stagePropValue === "yes") return setStage("furnished");

    if(actualStage.stage === "type" && stagePropValue === "commercial") return setStage("presentIndustry");
    if(actualStage.stage === "area" && stagePropValue === "yes") return setStage("areaSize");

    for (let i = actualIndex + 1; i < createSearchRoute.length; i++) {
      if (createSearchRoute[i].usage === "all" || createSearchRoute[i].usage.includes(searchData?.type)) {
        if(createSearchRoute[i].deal === "all" || createSearchRoute[i].deal.includes(searchData?.deal)) {
          if(createSearchRoute[i].commercialUsage === "all" || (searchData?.type === "commercial" ? createSearchRoute[i].commercialUsage?.includes(searchData?.commercialType) : true)) {
            if(createSearchRoute[i].areaUsage === "all" || createSearchRoute[i].areaUsage === searchData?.openAreas) {
              setStage(createSearchRoute[i].stage);
              return;
            }
          }
        }
      }
    }
  };

  const lastStage = () => {
    const actualIndex = createSearchRoute.findIndex(el => el.stage === stage);
    const actualStage = createSearchRoute[actualIndex];

    // Exceptions
    if(actualStage.stage === "furnished") return setStage("groundFloor");

    for (let i = actualIndex - 1; i >= 0; i--) {
      if (createSearchRoute[i].usage === "all" || createSearchRoute[i].usage.includes(searchData?.type)) {
        if(createSearchRoute[i].deal === "all" || createSearchRoute[i].deal?.includes(searchData?.deal)) {
          if(createSearchRoute[i].commercialUsage === "all" || (searchData?.type === "commercial" ? createSearchRoute[i].commercialUsage?.includes(searchData?.commercialType) : true)) {
            if(createSearchRoute[i].areaUsage === "all" || createSearchRoute[i].areaUsage === searchData?.openAreas) {
              setStage(createSearchRoute[i].stage);
              return;
            }
          }
        }
      }
    }
  };

  const create = async (isActive) => {
    setIsCreating(true);

    const polygonArray = searchData.polygon.map(el => ({
      type: el.pm._shape.toLowerCase(),
      center: el.pm._shape === "Circle" ? el.getLatLng() : el.getBounds().getCenter(),
      coordinates: el.pm._shape === "Circle" ? el.getLatLng() : el.getLatLngs()[0],
      radius: el.pm._shape === "Circle" ? el.getRadius() : 0
    }));
    await searchCreation({ search: {...searchData, polygon: polygonArray}, activate: isActive })
    .then(() => {
      handleMenuState("searches");
      toast.success(t('CreationDoneInfo'));
    });
    setIsCreating(false);
  };

  const handleReset = () => {
    setStage("urgency");
  };

  return (
    <div className='w-full flex flex-col mt-18 items-center px-1.5 py-5'>
      {stage === "urgency" && <Urgency next={() => nextStage()} />}
      {stage === "name" && <Name next={() => nextStage()} back={lastStage} />}      
      {stage === "type" && <Type next={(e) => nextStage(e)} back={lastStage} />}
      {stage === "isolated" && <Isolated next={() => nextStage()} back={lastStage} />}
      {stage === "deal" && <Deal next={() => nextStage()} back={lastStage} />}
      {stage === "commercialType" && <CommercialSearchType next={() => nextStage()} back={lastStage} />}
      {stage === "licensing" && <SearchLicensing next={() => nextStage()} back={lastStage} />}
      {stage === "presentIndustry" && <PresentIndustrySearch next={() => nextStage()} back={lastStage} />}
      {stage === "style" && <Style next={() => nextStage()} back={lastStage} />}
      {stage === "condition" && <Condition next={() => nextStage()} back={lastStage} />}
      {stage === "location" && <SearchMap next={() => nextStage()} back={lastStage} />}
      {stage === "price" && <Price next={() => nextStage()} back={lastStage} />}
      {stage === "size" && <Size next={() => nextStage()} back={lastStage} />}
      {stage === "land" && <Land next={() => nextStage()} back={lastStage} />}

      {stage === "squarePrice" && <SquarePrice next={() => nextStage()} back={lastStage} />}

      {stage === "rooms" && <Rooms next={() => nextStage()} back={lastStage} />}
      {stage === "units" && <Units next={() => nextStage()} back={lastStage} />}
      {stage === "vacancyRatio" && <VacancyRatio next={() => nextStage()} back={lastStage} />}
      {stage === "renovationRatio" && <RenovationRatio next={() => nextStage()} back={lastStage} />}
      {stage === "groundFloor" && <GroundFloor next={(e) => nextStage(e)} back={lastStage} />}
      {stage === "attic" && <Attic next={(e) => nextStage(e)} back={lastStage} />}
      {stage === "floor" && <Floor next={() => nextStage()} back={lastStage} />}
      {stage === "furnished" && <Furnished next={() => nextStage()} back={lastStage} />}
      {stage === "area" && <OpenAreas _withProp next={(e) => nextStage(e)} back={lastStage} />}
      {stage === "areaSize" && <OpenAreaSize next={() => nextStage()} back={lastStage} />}
      {stage === "areaType" && <OpenAreaType next={() => nextStage()} back={lastStage} />}
      {stage === "vacancy" && <Vacancy next={() => nextStage()} back={lastStage} />}
      {stage === "facilities" && <Facilities next={() => nextStage()} back={lastStage} />}
      {stage === "other" && <OtherInfos next={() => nextStage()} back={lastStage} />}
      {stage === "heating"  && <Heating next={() => nextStage()} back={lastStage} />}
      {stage === "heatingMulti"  && <HeatingMulti next={() => nextStage()} back={lastStage} />}
      {stage === "summary" && <Summary _create={create} _reset={handleReset} back={lastStage} />}
    </div>
  )
};

export default NewSearch
