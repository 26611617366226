import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from '../../Objects/Creation/StageLabel';
import RangeNumbers from './RangeNumbers';

const Size = ({next, back}) => {

    const { searchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const validation = () => {
        return [null, "NaN", NaN, undefined, "undefined", ""].includes(searchData.size[0]) || 
        ([null, "NaN", NaN, undefined, "undefined", ""].includes(searchData.size[1]) && !searchData.sizeUnlimited) || 
        (!searchData.sizeUnlimited && parseFloat(searchData?.size[0]) > parseFloat(searchData?.size[1]));
    };

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-2/3 md:w-1/2 mdd:w-1/3">
        <StageLabel color={false} stageIcon={require('../../../assets/animations/searchCreation/size.json')} labelText={["multi", "commercial"].includes(searchData.type) ? t("CreateSearch.SizeLabelMulti") : t('CreateSearch.SizeLabel')}/>
        <RangeNumbers 
          prop="size"
          unit="m²"
          resetValues={[0, 1000]}
        />
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={validation()}/>
    </div>
  )
};

export default Size
