import { createContext, useContext, useState } from "react";
import { deleteSingleObject, deleteSingleSearch } from "../helper/functions";
import { useStateContext } from "./ContextProvider";

const DeletionContext = createContext();

export const DeletionProvider = ({ children }) => {

    const { handleMenuState, menuState, customerObjects, customerSearches, setIsLoading } = useStateContext();
    
    const [deletedObject, setDeletedObject] = useState("");
    const [deletedSearch, setDeletedSearch] = useState("");
    const [objectDeleteConfirmOpen, setObjectDeleteConfirmOpen] = useState(false);
    const [searchDeleteConfirmOpen, setSearchDeleteConfirmOpen] = useState(false);
    const [objectDeletionSurveyOpen, setObjectDeletionSurveyOpen] = useState(false);
    const [searchDeletionSurveyOpen, setSearchDeletionSurveyOpen] = useState(false);

    const deleteObject = async (cause) => {
        if(deletedObject !== "") {
            setIsLoading(true);
            setObjectDeleteConfirmOpen(false);
            await deleteSingleObject({objectId: deletedObject, reason: cause})
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            })
        }
        if(customerObjects.length > 0  && menuState !== "objects") {
            handleMenuState("objects");
        } else {
            handleMenuState("objects");
        }
    };

    const deleteSearch = async(cause) => {
        if(deletedSearch !== "") {
            setIsLoading(true);
            setSearchDeleteConfirmOpen(false);
            await deleteSingleSearch({searchId: deletedSearch, reason: cause})
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            })
        }
        if(customerSearches.length > 0 && menuState !== "searches") {
            handleMenuState("searches");
        } else {
            handleMenuState("searches");
        }
    };
 
    return (
        <DeletionContext.Provider
            value={{ 
                deletedObject,
                objectDeleteConfirmOpen,
                deletedSearch,
                searchDeleteConfirmOpen,
                objectDeletionSurveyOpen,
                searchDeletionSurveyOpen,
                setSearchDeletionSurveyOpen,
                setObjectDeletionSurveyOpen,
                deleteObject,
                deleteSearch,
                setSearchDeleteConfirmOpen,
                setObjectDeleteConfirmOpen,
                setDeletedSearch,
                setDeletedObject
            }}>
                {children}
        </DeletionContext.Provider>
    )
}

export const useDeletionContext = () => useContext(DeletionContext);