import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { auth } from '../../../firebase.config';
import { Transition, acceptOffer, convertShortTimestamp } from '../../../helper/functions';
import AnimationIcon from '../../Generics/AnimationIcon';
import Button from '../../Generics/Button';
import { useSearchDetailsContext } from '../Contexts/SearchDetailsProvider';

const PendingOffers = () => {

    const { searchContacts } = useSearchDetailsContext();
    const { screenSize } = useStateContext();

    const pendingOffers = searchContacts?.filter(o => o.status === "pending");

  return (
    <div className={`${pendingOffers.length > 0 ? "block" : "hidden"} flex flex-col items-center justify-center w-full`}>
        <p className='text-14 w-full pl-0.5 ssm:pl-0'>{`${t('ObjectDetails.NeueAngebote')}:`}</p>
        <div className={`grid mt-1 ${screenSize.width > 800 ? "grid-cols-5" : screenSize.width > 640 ? "grid-cols-4" : screenSize.width > 450 ? "grid-cols-3" : "grid-cols-2"} w-full gap-2`}>
            {pendingOffers?.map((p, i) => {
            return (
                <PendingOffer key={i} data={p} index={i} />
            )
            })}
        </div>

        {/* <div className={`w-full gap-1 flex flex-col border rounded border-allimmoDark p-1`}>
            {pendingOffers?.map((p, i) => {
            return (
                <PendingOffer2 key={i} data={p} index={i} />
            )
            })}
        </div> */}
    </div>
  )
};

const PendingOffer = ({data, index}) => {

    const { setIsLoading, customer } = useStateContext();

    const [comissionContractOpen, setComissionContractOpen] = useState(false);
    const [contracts, setContracts] = useState({
        comission: false,
        privacy: false,
    });

    const handleOfferAccept = async () => {
        setComissionContractOpen(false);
        setIsLoading(true);
        await acceptOffer({contactID: data.id, customerID: data.customer, objectID: data.object, searchID: data.search, senderName: customer?.name, email: auth.currentUser.email})
        .catch(() => {

            setIsLoading(false);
        })
        setIsLoading(false);
    };

    const handleComissionContract = () => {
        if(data?.comission) {
            setContracts({
                comission: false,
                privacy: false,
              })
            setComissionContractOpen(true);
        } else {
            handleOfferAccept();
        }
    };

    return (
            <div onClick={handleComissionContract} className={`${index <= 4 ? "block" : "hidden"} relative group cursor-pointer w-full h-44 max-w-48 md:max-w-44 rounded-lg`}>
                <div className="absolute -inset-1 bg-gradient-to-r from-allimmoDark via-allimmoDark/30 to-allimmoDark rounded-lg blur opacity-20 group-hover:opacity-50 transition duration-1000 group-hover:duration-200">
                </div>
                <div className="border-dashed border border-allimmoDark/80 relative py-2 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex flex-col items-center justify-between gap-1 h-full">
                    <div className='flex flex-row justify-center items-center w-full'>
                        <p className={`border rounded-full text-center px-1.5 text-allimmoDark border-allimmoDark/80 font-bold text-sm`}>{index + 1}</p>
                    </div>  
                    <div className='flex flex-row justify-center items-center border-allimmoDark/40 w-full bg-allimmoDark/10 h-14 py-1 px-2 border-y border-dashed'> 
                        <p className={`text-allimmoDark/80 text-sx text-center font-semibold w-full`}>{data?.address}</p>
                    </div>
                    <p className='text-red-600 italic text-12 opacity-60 text-center'>{`${t('SearchDetails.LäuftAb')} ${convertShortTimestamp(data.timeout?.toDate())}`}</p>
                    <div className='w-full flex flex-row items-center justify-center gap-2'>
                        <p className='text-allimmoDark/80'>{t('SearchDetails.PendingOffer.ClickHere')}</p>
                        <AnimationIcon animate iconSrc={require('../../../assets/animations/tap.json')} _iconSize={40} _color={false} />
                    </div>
                </div>
            <Dialog className='select-none' open={comissionContractOpen} fullWidth={false} TransitionComponent={Transition}>
                <div className='flex flex-col justify-center items-center p-5 space-y-3'>
                    <p className='text-xl'>{t('Vereinbarungen.Header')}</p>
                </div>

                {data.privacyLink !== "" && 
                <div className='flex flex-row items-center pb-5 px-10 space-x-3'>
                    <FormControlLabel control={<Checkbox checked={contracts.privacy} value={contracts.privacy} onChange={() => setContracts({...contracts, privacy: !contracts.privacy})} disableRipple disableTouchRipple size="small"/>} label={t('Vereinbarungen.Datenschutzvereinbarung')} />
                    <a target='_blank' rel="noreferrer" href={data?.privacyLink} className='grow text-end hover:underline cursor-pointer text-allimmoDark/50 active:bg-allimmoDark/50 hover:text-allimmoDark'>{t('General.Ansehen')}</a>
                </div>}
                <p className='text-sm text-center px-10'>{t('Vereinbarungen.Erklärung')}</p>

                <a target='_blank' rel="noreferrer" href={data?.comissionContractLink} className='hover:underline w-full text-center mt-5 cursor-pointer text-allimmoDark/50 active:bg-allimmoDark/50 hover:text-allimmoDark'>{t('Vereinbarungen.Provisionsvereinbarung')}</a>


                <div className='flex flex-row justify-center items-center space-x-2 my-5'>
                    <Button text={t('General.Abbrechen')} clickFunction={() => setComissionContractOpen(false)}/>
                    <Button text={t('Vereinbarungen.Bestätigen')} clickFunction={handleOfferAccept}/>
                </div>
            </Dialog>
        </div>
    )
};

const PendingOffer2 = ({data, index}) => {

    const { setIsLoading, customer } = useStateContext();

    const [comissionContractOpen, setComissionContractOpen] = useState(false);
    const [contracts, setContracts] = useState({
        comission: false,
        privacy: false,
    });

    const handleOfferAccept = async () => {
        setComissionContractOpen(false);
        setIsLoading(true);
        await acceptOffer({contactID: data.id, customerID: data.customer, objectID: data.object, searchID: data.search, senderName: customer?.name, email: auth.currentUser.email})
        .catch(() => {

            setIsLoading(false);
        })
        setIsLoading(false);
    };

    const handleComissionContract = () => {
        if(data?.comission) {
            setContracts({
                comission: false,
                privacy: false,
              })
            setComissionContractOpen(true);
        } else {
            handleOfferAccept();
        }
    };

    return (
            <div onClick={handleComissionContract} className={`${index <= 4 ? "block" : "hidden"} relative group cursor-pointer w-full h-44 rounded-lg`}>
                <div className="absolute -inset-1 bg-gradient-to-r from-allimmoDark via-allimmoDark/30 to-allimmoDark rounded-lg blur opacity-20 group-hover:opacity-50 transition duration-1000 group-hover:duration-200">
                </div>
                <div className="border-dashed border border-allimmoDark/80 relative py-2 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex flex-row items-center">
                    <div className='flex flex-row justify-center items-center w-full'>
                        <p className={`border rounded-full text-center px-1.5 text-allimmoDark border-allimmoDark/80 font-bold text-sm`}>{index + 1}</p>
                    </div>  
                    <p className={`text-allimmoDark/80 text-sx text-center font-semibold w-full`}>{data?.address}</p>
                    <p className='text-red-600 italic text-12 opacity-60 text-center'>{`${t('SearchDetails.LäuftAb')} ${convertShortTimestamp(data.timeout?.toDate())}`}</p>
                    <div className='w-full flex flex-row items-center justify-center gap-2'>
                        <p className='text-allimmoDark/80'>{t('SearchDetails.PendingOffer.ClickHere')}</p>
                        <AnimationIcon animate iconSrc={require('../../../assets/animations/tap.json')} _iconSize={40} _color={false} />
                    </div>
                </div>
            <Dialog className='select-none' open={comissionContractOpen} fullWidth={false} TransitionComponent={Transition}>
                <div className='flex flex-col justify-center items-center p-5 space-y-3'>
                    <p className='text-xl'>{t('Vereinbarungen.Header')}</p>
                </div>

                {data.privacyLink !== "" && 
                <div className='flex flex-row items-center pb-5 px-10 space-x-3'>
                    <FormControlLabel control={<Checkbox checked={contracts.privacy} value={contracts.privacy} onChange={() => setContracts({...contracts, privacy: !contracts.privacy})} disableRipple disableTouchRipple size="small"/>} label={t('Vereinbarungen.Datenschutzvereinbarung')} />
                    <a target='_blank' rel="noreferrer" href={data?.privacyLink} className='grow text-end hover:underline cursor-pointer text-allimmoDark/50 active:bg-allimmoDark/50 hover:text-allimmoDark'>{t('General.Ansehen')}</a>
                </div>}
                <p className='text-sm text-center px-10'>{t('Vereinbarungen.Erklärung')}</p>

                <a target='_blank' rel="noreferrer" href={data?.comissionContractLink} className='hover:underline w-full text-center mt-5 cursor-pointer text-allimmoDark/50 active:bg-allimmoDark/50 hover:text-allimmoDark'>{t('Vereinbarungen.Provisionsvereinbarung')}</a>


                <div className='flex flex-row justify-center items-center space-x-2 my-5'>
                    <Button text={t('General.Abbrechen')} clickFunction={() => setComissionContractOpen(false)}/>
                    <Button text={t('Vereinbarungen.Bestätigen')} clickFunction={handleOfferAccept}/>
                </div>
            </Dialog>
        </div>
    )
};

export default PendingOffers
