import { Collapse, TextField } from '@mui/material';
import { GoogleAuthProvider, browserSessionPersistence, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import AllimmoMatchLogo from '../assets/AllimmoMatchLogo.png';
import { useStateContext } from '../contexts/ContextProvider';
import { auth } from '../firebase.config';
import { handleGoogleSignIn, handleMicrosoftSignIn } from '../helper/functions';
import { CloseCircle } from '../helper/icons';
import Button from './Generics/Button';
import validator from 'validator';

const LoginHandler = () => {

  const { setLoginOpen, loginHandlerState, setLoginHandlerState } = useStateContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
      <div className='flex flex-col justify-center items-center p-3 select-none w-full'>
        <div className='w-full flex flex-roe justify-end'>
          <CloseCircle onClick={() => setLoginOpen(false)} className='cursor-pointer hover:text-slate-300 active:text-allimmoDark text-allimmoDark' size={30} />
        </div>
        <img className='mb-2 w-180 sm:w-200' src={AllimmoMatchLogo} alt='ALLIMMO'/>

        {loginHandlerState === "login" ? 
        <Login toRegister={() => setLoginHandlerState("register")} close={() => setLoginOpen(false)} /> : 
        <Register toLogin={() => setLoginHandlerState("login")} close={() => setLoginOpen(false)} />}
      </div>
    )
};

const Login = ({toRegister, close}) => {

  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });
  const [alert, setAlert] = useState({
    text: "",
    open: false
  });

  const [isLoading, setIsLoading] = useState(false);
  const [resetSuccessOpen, setResetSuccessOpen] = useState(false);
  

  useEffect(() => {
    resetAlert();
  }, [loginData]);
  

  const openAlert = (newText) => {
    setAlert({text: newText, open: true});
  };

  const handleSignIn = (e) => {
    resetAlert();
    if(loginData.email === "" || loginData.password === "") {
      openAlert(t('Alerts.Ausfüllen'));
    } else {
      
      if(e) e.preventDefault();
      setIsLoading(true);
      setPersistence(auth, browserSessionPersistence)
      .then(async () => {
        await signInWithEmailAndPassword(auth, loginData.email, loginData.password)
        .then(() => {
          close();
        })
        .catch((error) => {
          setIsLoading(false);
          switch(error.code) {
            case "auth/network-request-failed":
              openAlert(t('Alerts.NoInternet'));
              break;
            case "auth/user-not-found":
              openAlert(t('Alerts.EmailNotFound'));
              break;
            case "auth/wrong-password":
            case "auth/invalid-login-credentials":
            case "auth/invalid-email":
              openAlert(t('Alerts.WrongCombo'));
              break;
            default:
              openAlert(error.code);
              break;
          }
        })
      })
    }
  };

  const resetAlert = () => {
    setAlert({...alert, open: false});
  };

  const resetPassword = async () => {
    if(validator.isEmail(loginData.email.trim())) {
      await sendPasswordResetEmail(auth, loginData.email.trim())
      .then(() => {
        setResetSuccessOpen(true);
      })
    } else {
      openAlert(t('Alerts.NoValidEmail'));
    }
  };

  const handleEnterPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleSignIn(event);
    }
  };

  return (
    <div className='flex flex-col justify-center items-center pb-5 pt-2 px-1 select-none space-y-2 w-full md:w-1/2'>

      <div className='flex flex-row p-2 border space-x-3 border-allimmoDark/20 rounded justify-center w-270 cursor-pointer hover:bg-allimmoDark/5' onClick={() => handleGoogleSignIn()}>
        <img src='https://www.svgrepo.com/show/475656/google-color.svg' width={25}/>
        <p>{t('SocialLogin.GoogleLogin')}</p>
      </div>

      {/* <p onClick={handleMicrosoftSignIn}>MICROSOFT</p> */}

      <div className='flex flex-row items-center space-x-3 w-270 my-5'>
        <p className='border-b border-allimmoDark w-full'></p>
        <p className='text-allimmoDark w-1/3 text-center'>oder</p>
        <p className='border-b border-allimmoDark w-full'></p>
      </div>
      
      <div className='flex flex-col w-270 items-center space-y-2'>
        <TextField
        required
        onKeyDown={(e) => handleEnterPress(e)}
        className='w-full'
        size='small'
        label={t('General.Email')}
        value={loginData.email}
        onChange={(e) => setLoginData({...loginData, email: e.target.value})}/>

        <TextField
        required
        onKeyDown={(e) => handleEnterPress(e)}
        className='w-full'
        size='small'
        type='password'
        label={t('General.Passwort')}
        value={loginData.password}
        onChange={(e) => setLoginData({...loginData, password: e.target.value})}/>

        <Collapse in={alert.open}>
          <p className='bg-red-50 px-2 text-red-400 w-full text-center text-sm'>{alert.text}</p>
        </Collapse>

        <Collapse in={resetSuccessOpen}>
          <p className='bg-green-50 px-2 text-green-600 w-full text-center text-sm'>{t('Alerts.ResetSuccess')}</p>
        </Collapse>
      
        <Button
          marginTop={5}
          width={`100%`}
          text={t('General.Login')}
          loading={isLoading}
          clickFunction={handleSignIn}
        />
      </div>
      
      <div className='flex flex-row space-x-1 items-center mt-3'>
      <p onClick={toRegister} className='cursor-pointer hover:underline text-xs'>{t('General.Registrieren')}</p>
      <p>|</p>
      <p onClick={resetPassword} className='cursor-pointer hover:underline text-xs'>{t('General.PasswortVergessen')}</p>
      </div>
    </div>
  )
};

const Register = ({toLogin, close}) => {

  const [registerData, setRegisterData] = useState({
      email: "",
      password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({text: "", open: false});

  useEffect(() => {
    resetAlert();
  }, [registerData]);

  const resetAlert = () => {
    setAlert({
      text: "",
      open: false,
    });
  };
  

  const openAlert = (newText) => {
    setAlert({text: newText, open: true});
  };

  const handleRegister = async () => {
    resetAlert();
      if(registerData.email !== "" && registerData.password.length >= 8) {
          setIsLoading(true);
          close();
          await createUserWithEmailAndPassword(auth, registerData.email, registerData.password)
          .then(() => sendEmailVerification(auth.currentUser))
          .catch((error) => {
            setIsLoading(false);
            switch(error.code) {
              case "auth/network-request-failed":
                openAlert(t('Alerts.NoInternet'));
                break;
              case "auth/email-already-in-use":
                openAlert(t('Alerts.EmailAlready'));
                break;
              case "auth/user-not-found":
                openAlert(t('Alerts.EmailNotFound'));
                break;
                case "auth/wrong-password":
                case "auth/invalid-email":
                openAlert(t('Alerts.WrongCombo'));
                break;
              default:
              openAlert(error.code);
                break;
            }
          })
      } else if (registerData.email === "") {
        openAlert("Bitte Email-Adresse eingeben!");
      } else if (registerData.password.length < 8) {
        openAlert("Das Passwort Muss mindestens 8 Zeichen haben!");
      }
      setIsLoading(false);
  };

  const handleEnterPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleRegister();
    }
  };
    
return (
  <div className='flex flex-col justify-center items-center pb-5 pt-2 select-none space-y-2 w-270 md:w-1/2'>

    <div onClick={() => handleGoogleSignIn(new GoogleAuthProvider())} className='flex flex-row p-2 w-270 justify-center border space-x-3 border-allimmoDark/20 rounded cursor-pointer hover:bg-allimmoDark/5'>
      <img src='https://www.svgrepo.com/show/475656/google-color.svg' width={25}/>
      <p>{t('SocialLogin.GoogleRegister')}</p>
    </div>

    {/* <div className='flex flex-row p-2 border space-x-3 border-allimmoDark/20 rounded justify-center w-270 cursor-pointer hover:bg-allimmoDark/5' onClick={() => handleFacebookSignIn()}>
      <img src='https://www.svgrepo.com/show/475647/facebook-color.svg' width={25}/>
      <p>{t('SocialLogin.FacebookRegister')}</p>
    </div> */}

    <div className='flex flex-row items-center space-x-3 w-270 my-5'>
      <p className='border-b border-allimmoDark w-full'></p>
      <p className='text-allimmoDark w-1/3 text-center'>oder</p>
      <p className='border-b border-allimmoDark w-full'></p>
    </div>
    
    <TextField
    required
    className='w-270'
    size='small'
    label={t('Register.Email')}
    value={registerData.email}
    onChange={(e) => setRegisterData({...registerData, email: e.target.value})}/>

    <TextField
    required
    className='w-270'
    onKeyDown={(e) => handleEnterPress(e)}
    type='password'
    size='small'
    label={t('General.Passwort')}
    value={registerData.password}
    helperText={t('Register.PasswortInfo')}
    onChange={(e) => setRegisterData({...registerData, password: e.target.value})}/>

    <Collapse className='w-270' in={alert.open}>
      <p className='bg-red-50 px-2 text-red-400 w-full text-center text-sm'>{alert.text}</p>
    </Collapse>

    <Button
      marginTop={5}
      width={`100%`}
      text={t('General.Registrieren')}
      loading={isLoading}
      clickFunction={handleRegister}
    />
    <div className='flex flex-row space-x-1'>
      <p className='text-xs mt-1'>{t('Register.SchonRegistriert')}</p>
      <p onClick={toLogin} className='cursor-pointer hover:underline text-xs mt-1 text-allimmoDark'>{t('Register.ZumLogin')}</p>
    </div>
  </div>
)
};

export default LoginHandler