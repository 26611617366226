import { t } from 'i18next';
import Button from '../Generics/Button';
import { Delete, Dot } from '../../helper/icons';
import { useEffect, useState } from 'react';
import { updateFirestoreDoc, updateObject } from '../../helper/functions';
import { allimmoObjectDataTypes } from '../../helper/arrays';
import { Checkbox } from '@mui/material';
import { useStateContext } from '../../contexts/ContextProvider';

const AddMissingData = ({obj, arrayName, close}) => {

    const { screenSize } = useStateContext();

    const [newValues, setNewValues] = useState({active: obj.active});
    const [isSaving, setIsSaving] = useState(false);
    const [areas, setAreas] = useState(obj.openAreas);

    const disableCheck = isSaving || (Object.keys(newValues).filter((k) => k !== "active").length === 0 && (areas.filter(a => Object.values(a).includes("undefined")).length > 0 || areas.filter(a => Object.values(a).includes("")).length > 0))

    useEffect(() => {
      if (arrayName === "missingParams" && Object.keys(newValues).filter((k) => k !== "active").length === 0 && newValues.active) setNewValues({active: obj.active});
    }, [newValues]);

    // useEffect(() => {
    //     console.log("NEW AREAS: ", Object.keys({...newValues, openAreas: areas}));

    // }, [areas])
    

    const handleValueChange = async (e, item) => {
        if(e === "") {
            delete newValues[item]
            setNewValues({...newValues});
        } else {
            setNewValues({...newValues, [item]: e});
        }
    };    

    const saveValues = async () => {
        close();
        setIsSaving(true);
        await updateObject({objectId: obj.id, updateData: {...newValues, openAreas: areas}, incompleteDataType: arrayName})
        .then(() => {
            setNewValues({});
        })
    };

    const handleAreaChange = (e) => {
        setAreas([...areas.filter(a => a.id !== e.id), e]);
    };

    return (
        <div className={`w-full flex flex-col py-3 ${screenSize.width > 500 ?"px-3" : "px-1"} -mt-2`}>
            <p className='text-lg text-allimmoDark'>{t("MissingData.AddMissingDataPopup")}</p>
            {arrayName === "missingParams" && <p className='text-sm italic text-red-400'>{t("MissingData.MissingDataInfo")}</p>}
            {arrayName === "undefinedParams" && <p className='text-sm italic text-allimmoDark'>{t("MissingData.UndefinedDataInfo")}</p>}
            {arrayName === "undefinedParams" && 
            <p className='hover:bg-allimmoDark/60 active:bg-allimmoDark/80 mt-2 cursor-pointer w-full border-2 rounded py-1 text-center border-allimmoDark bg-allimmoDark/80 text-white shadow-lg' onClick={() => updateFirestoreDoc("objects", obj.id, {ignoreUndefined: true})}>{t('MissingData.Ignore')}</p>}
            
            <div className='mt-3 mb-2 border border-allimmoDark/40 p-1 rounded flex flex-col'>
                {
                    obj[arrayName].filter(e => !e.startsWith("openAreas")).map((_item, _index) => {
                        return (
                            <div>
                                <IncompleteElement
                                object={obj}
                                key={_index} 
                                item={_item}
                                index={_index}
                                newValue={newValues[_item]} 
                                change={handleValueChange} 
                                />
                            </div>
                        )
                    })
                }

                {(obj[arrayName].some((item) => item.includes("openAreas")) && arrayName === "undefinedParams") &&
                    obj.openAreas.filter(a => Object.values(a).includes("undefined")).map((area, index) => {
                        return (
                            <IncompleteAreaElement 
                            key={index} 
                            areaData={area} 
                            _index={index} 
                            object={obj} 
                            change={handleAreaChange}
                            _areas={areas}/>
                        )
                    })
                }
                {/* {saved && <p className='text-green-500 w-full text-end animate-pulse text-sm mt-2'>{"gespeichert"}</p>} */}
            </div>
            {(arrayName === "missingParams" && (obj?.missingParams?.length === Object.keys(newValues).filter(val => val !== "active").length)) &&
            <div className='flex flex-row items-center -ml-2.5'>
                <Checkbox disableTouchRipple onChange={() => setNewValues({...newValues, active: !newValues.active ?? false})} value={newValues.active ?? false} checked={newValues.active ?? false} />
                <p className='text-allimmoDark'>{t('AddMissingData.ActivateAfterSave')}</p>
            </div>}
            <div className='w-full flex flex-row items-center justify-center gap-2 mt-2'>
                <Button isDisabled={disableCheck} clickFunction={saveValues} text={t("General.Speichern")} />
                <Button isDisabled={isSaving} clickFunction={close} text={t("General.Schliessen")} />
            </div>
        </div>
    )
};

const IncompleteElement = ({item, change, newValue, index}) => {

    const { screenSize } = useStateContext();
    let itemName = item;  

    if (item?.includes("openAreas")) {
        itemName = "openAreas";
    } else if (item.includes(".")) {
        itemName = itemName.split(".")[1];
    }

    const handleSelectChange = (e, item) => {
        if (e === "true") return change(true, item);
        if (e === "false") return change(false, item);
    };

    const handleStringChange = (e, item) => {
        if (item === "streetNumber") return change(e, "address.streetNumber");
        if (item === "cityZip") return change(e, "address.cityZip");
        change(e, item);
    }; 

    return (
        <div className={`${screenSize.width < 500 ? "pl-0.5" : ""} flex flex-row items-center gap-2 ${index > 0 ? "mt-1" : ""}`}>
            {screenSize.width > 500 && <Dot className='text-allimmoDark' size={16}/>}
            <p className='grow'>{t(`AllimmoObject.${itemName}`)}</p>

            {allimmoObjectDataTypes[itemName] === "string" && 
            <input onChange={(e) => handleStringChange(e.target.value, item)} value={newValue} className={`border py-0.5 px-2 rounded w-180`} placeholder={t(`AllimmoObject.${itemName}`)}/>}
            
            {allimmoObjectDataTypes[itemName] === "bool" && 
            <select defaultValue="x" onChange={(e) => handleSelectChange(e.target.value, itemName)} value={newValue || undefined} className={`border py-0.5 px-2 rounded w-180`}>
                <option value="x" disabled>{t("General.Auswählen")}</option>
                <option value={true}>{t("General.Ja")}</option>
                <option value={false}>{t("General.Nein")}</option>
            </select>}

            {allimmoObjectDataTypes[itemName] === "facility" && 
            <select defaultValue="x" onChange={(e) => handleSelectChange(e.target.value, itemName)} value={newValue || undefined} className={`border py-0.5 px-2 rounded w-180`}>
                <option value="x" disabled>{t("General.Auswählen")}</option>
                <option value={true}>{t("General.Vorhanden")}</option>
                <option value={false}>{t("General.NichtVorhanden")}</option>
            </select>}

            {Array.isArray(allimmoObjectDataTypes[itemName]) && 
            <select defaultValue="x" onChange={(e) => change(e.target.value, item)} value={newValue} className={`border py-0.5 px-2 rounded w-180`}>
                <option value="x" disabled>{t("General.Auswählen")}</option>
                {allimmoObjectDataTypes[itemName].map((_item, _index) => {
                    return <option key={_index} value={_item}>{t(`AllimmoObject.${itemName + "Options"}.${_item}`)}</option>
                })}
            </select>}
        </div>
    )
};

const IncompleteAreaElement = ({areaData, _index, object, change, _areas}) => {
    const areaSize = _areas.find(e => e.id === areaData.id).size;
    const isAreaPublic = _areas.find(e => e.id === areaData.id).public;
    const areaType = _areas.find(e => e.id === areaData.id).type;

    const deleteOpenArea = async () => {
        // console.log("ID, INDEX: ", object?.id, areaData.id);
        // return console.log("INDEX", `openAreas.${object.openAreas.map(e => e.id).indexOf(areaData.id)}.`);
        await updateFirestoreDoc("objects", object?.id, {
            openAreas: object.openAreas.filter((area) => area.id !== areaData.id),
            missingParams: object.missingParams.filter((param) => !param.includes(`openAreas.${object.openAreas.map(e => e.id).indexOf(areaData.id)}.`)),
            undefinedParams: object.undefinedParams.filter((param) => !param.includes(`openAreas.${object.openAreas.map(e => e.id).indexOf(areaData.id)}.`)),
        });
    };

    return (
        
        <div className={`flex flex-row items-center gap-1 mt-1`}>
            <div className='flex flex-col w-full border border-allimmoDark/40 bg-allimmoDark/5 gap-1 py-1 px-1.5 border-dashed rounded'>
            {["missing", "undefined"].includes(areaData?.type) ? 
            <select className='w-full px-0.5 rounded' defaultValue="x" onChange={(e) => change({
                size: areaSize,
                type: e.target.value,
                public: isAreaPublic,
                id: areaData.id,
            })}>
                <option value="x" disabled>{t("General.ChooseAreaType")}</option>
                <option value="balcony">{t("AllimmoObject.openAreaOptions.balcony")}</option>
                <option value="roofTop">{t("AllimmoObject.openAreaOptions.roofTop")}</option>
                <option value="garden">{t("AllimmoObject.openAreaOptions.garden")}</option>
                {/* <option value="loggia">{t("AllimmoObject.openAreaOptions.logia")}</option> */}
                <option value="parking">{t("AllimmoObject.openAreaOptions.parking")}</option>
                <option value="other">{t("AllimmoObject.openAreaOptions.other")}</option>
            </select> :
            <p className='w-full pl-0.5'>{t(`AllimmoObject.openAreaOptions.${areaData?.type}`)}</p>}
            <div className='flex flex-row gap-1 items-center justify-between w-full'>
                {["missing", "undefined"].includes(areaData?.size) ?
                <input onChange={(e) => change({
                    size: e.target.value,
                    type: areaType,
                    public: isAreaPublic,
                    id: areaData.id,
                })} value={["missing", "undefined"].includes(areaSize) ? "m² " + t('MissingData.defaultValue') : areaSize || undefined} className={`border py-0.5 px-2 rounded w-180`} placeholder={t(`General.Größe`)}/> :
                <p className='text-xs'>{areaData?.size}m²</p>}

                {["undefined", "missing"].includes(areaData?.public) ?
                <select onChange={(e) => change({
                    size: areaSize,
                    type: areaType,
                    public: e.target.value,
                    id: areaData.id,
                    })} value={isAreaPublic === "undefined" ? "x" : isAreaPublic || undefined} className={`border py-0.5 px-2 rounded w-180`}>
                    <option value="x" disabled>{t("CreateObject.Allgemeinfläche")}</option>
                    <option value={true}>{t("General.Ja")}</option>
                    <option value={false}>{t("General.Nein")}</option>
                </select> :
                <p className='text-xs'>{areaData?.public}</p>}
            </div>
            
        </div>
        
        <Delete className='text-red-400 cursor-pointer hover:opacity-50' size={20} onClick={deleteOpenArea}/>
        </div>
 )
};

export default AddMissingData
