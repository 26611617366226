import { t } from "i18next";

const EventLogos = () => {

    const openLink = (link) => {
        window.open(link, '_blank')
    };

    return (
        <div className='flex flex-col items-center mt-12'>
            <div className='flex flex-row gap-4 items-center justify-center mb-1'>
                <div className='w-12 border-b border-allimmoDark/60' />
                <p className='text-allimmoDark/80 mb-3'>{t('Dashboard.BekanntAus').toUpperCase()}</p>
                <div className='w-12 border-b border-allimmoDark/60' />
            </div>
            <div className="w-full max-w-320 sm:max-w-380 inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
                <ul className="flex items-center justify-center md:justify-start [&_li]:mr-10 [&_img]:max-w-none animate-infinite-scroll">
                    <li>
                        <img onClick={() => openLink('https://www.apti.at/')} className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2Fapti.png?alt=media&token=b39a3c87-452a-4c5e-a22d-90ec92107431" alt="apti" />
                    </li>
                    <li>
                        <img onClick={() => openLink('https://4gamechangers.io/')} className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2F4gamechangers.png?alt=media&token=9a94a984-a259-476d-bccc-0caea2ee768b" alt="4Gamechangers" />
                    </li>
                    <li>
                        <img onClick={() => openLink('https://erstewohnmesse.at/')} className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2FersteMesse.png?alt=media&token=266b3825-5f37-440b-9e42-625cfb0c1297" alt="ERSTE Wohnmesse" />
                    </li>
                    <li>
                        <img onClick={() => openLink('https://brutkasten.com/artikel/allimmo-tindern-fuer-immobilien')} className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2Fbrutkasten.png?alt=media&token=00eceb8d-63b5-4bde-8d1f-5a2371a99319" alt="Brutkasten" />
                    </li>
                    <li>
                        <img onClick={() => openLink('https://proptech-events.com/proptech-vienna/')} className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2FpropTechVienna.png?alt=media&token=c9032cb0-dfea-479a-86f5-fa68a4166615" alt="Prop Tech Vienna" />
                    </li>
                    <li>
                        <img onClick={() => openLink('https://www.real-estate-arena.com/')} className='h-12 rounded-lg cursor-pointer hover:opacity-50'src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2FrealEstateArena.png?alt=media&token=96432be1-3e04-4f76-bf1a-ed6e35abf093" alt="Real Estate Arena" />
                    </li>
                </ul>

                <ul className="flex items-center justify-center md:justify-start [&_li]:mr-10 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
                    <li>
                        <img className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2Fapti.png?alt=media&token=b39a3c87-452a-4c5e-a22d-90ec92107431" alt="apti" />
                    </li>
                    <li>
                        <img className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2F4gamechangers.png?alt=media&token=9a94a984-a259-476d-bccc-0caea2ee768b" alt="4Gamechangers" />
                    </li>
                    <li>
                        <img className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2FersteMesse.png?alt=media&token=266b3825-5f37-440b-9e42-625cfb0c1297" alt="ERSTE Wohnmesse" />
                    </li>
                    <li>
                        <img className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2Fbrutkasten.png?alt=media&token=00eceb8d-63b5-4bde-8d1f-5a2371a99319" alt="Brutkasten" />
                    </li>
                    <li>
                        <img className='h-12 rounded-lg cursor-pointer hover:opacity-50' src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2FpropTechVienna.png?alt=media&token=c9032cb0-dfea-479a-86f5-fa68a4166615" alt="Prop Tech Vienna" />
                    </li>
                    <li>
                        <img className='h-12 rounded-lg cursor-pointer hover:opacity-50'src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2Feventlogos%2FrealEstateArena.png?alt=media&token=96432be1-3e04-4f76-bf1a-ed6e35abf093" alt="Real Estate Arena" />
                    </li>
                </ul>                
            </div>
        </div>
    )
};

export default EventLogos
