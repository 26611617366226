import { CircularProgress, Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Rainbow } from '../../helper/icons'
import { t } from 'i18next';
import { orange } from '@mui/material/colors';
import { NumericFormat } from 'react-number-format';
import { objectMatchingAnalysis } from '../../helper/functions';
import GaugeChart from 'react-gauge-chart';

const Analysis = ({_open, close, object}) => {

    const [analizing, setAnalizing] = useState(true);
    const [result, setResult] = useState(null);
    const [showNumbers, setShowNumbers] = useState(true);

    useEffect(() => {
        if(_open) startAnalysis();
    }, [_open])

    const startAnalysis = async () => {
        setAnalizing(true);
        const analysis = await objectMatchingAnalysis({
            objectId: object.id, 
            weeks: 52, 
            priceReductionValues: [1, 3, 5, 7, 10, 15, 50, 80, 90], 
            facilities: ["airCondition", "bathtub", "blinds", "kitchen", "cooling"],
        });
        // console.log("ANALYSIS DONE:", analysis.data);
        
        setResult(analysis.data);
        setAnalizing(false);
    };

    useEffect(() => {
      if(result !== null) console.log(result)
    }, [result])
    

  return (
    <Dialog open={_open} fullScreen>
        <div className='flex flex-col w-full items-center px-2 py-5 md:px-5'>
            <Rainbow size={55} className='text-allimmoDark' />
            <p className='text-xl'>{object.address.streetNumber}, {object.address.cityZip}</p>
            {!analizing && result?.searches < 2000 &&
            <div className='flex flex-row items-center gap-1 -mt-1 text-sm'>
                <p>{`${result.searches} ${t('Analysis.AnalyzedSearches')} in`}</p>
                <p><NumericFormat decimalScale={2} suffix='ms' displayType="text" thousandSeparator  value={result.duration}/></p>
            </div>}
            {analizing && 
            <div className='flex flex-col items-center justify-center w-full text-orange-500 gap-2 mt-10'>
                <CircularProgress size={50} sx={{color: orange[500]}}/>
                <p className='text-lg'>{t('Analysis.Analyzing')}</p>
            </div>}


            {!analizing && 
            <div className='flex flex-col items-center justify-center my-2 mdd:w-2/3 max-w-1100'>
                
                {/* <p className='-mt-1 text-xs'>{`${t('Analysis.Finished')} `}</p> */}
                {/* {result !== null && (!Object.values(result).some(e => e > 0) && 
                !Object.values(result.parking).some(e => e > 0) && 
                !Object.values(result.facilities).some(e => e > 0)) && <p className='font-bold mt-5 text-red-500'>KEINE ERBENISSE GEFUNDEN</p>} */}
                <p className='px-2 py-1 my-5 text-sm w-full text-center text-allimmoDark mt-5 bg-allimmoDark/10 rounded'>{t('Analysis.InfoText')}</p>
                
                <div className='grid grid-cols-2 ssm:grid-cols-3 md:grid-cols-5 gap-2'>
                    {result && result.price && result.price.map((data, index) => {
                        return (
                            <ResultElement withNumbers={showNumbers} visibleCondition={!result.price.some(p => p.reduction < data.reduction && p.matches === data.matches)} name="priceMinus" _result={result} _data={data} key={index} calculation={object.price * (1 - (data.reduction / 100))}/>
                        )
                    })}
                    {result.furnished &&
                    <ResultElement withNumbers={showNumbers} name="furnished" _result={result} _data={{matches: Math.floor((Math.random() * 20) + 1)}} percentage={false} />}
                    {result.notFurnished &&
                    <ResultElement withNumbers={showNumbers} name="notFurnished" _result={result} _data={{matches: Math.floor((Math.random() * 20) + 1)}} percentage={false} />}
                    {result.renovated &&
                    <ResultElement withNumbers={showNumbers} name="renovated" _result={result} _data={{matches: Math.floor((Math.random() * 20) + 1)}} percentage={false} />}
                    {result.noGas &&
                    <ResultElement withNumbers={showNumbers} name="noGas" _result={result} _data={{matches: Math.floor((Math.random() * 20) + 1)}} percentage={false} />}
                    {result.noCompensation &&
                    <ResultElement withNumbers={showNumbers} name="noCompensation" _result={result} _data={{matches: Math.floor((Math.random() * 20) + 1)}} percentage={false} />}
                    {result.airCondition &&
                    <ResultElement withNumbers={showNumbers} name="airCondition" _result={result} _data={{matches: result.airCondition}} percentage={false} />}
                    {result.bathtub &&
                    <ResultElement withNumbers={showNumbers} name="bathtub" _result={result} _data={{matches: result.bathtub}} percentage={false} />}
                    {result.blinds &&
                    <ResultElement withNumbers={showNumbers} name="blinds" _result={result} _data={{matches: result.blinds}} percentage={false} />}
                    {result.kitchen &&
                    <ResultElement withNumbers={showNumbers} name="kitchen" _result={result} _data={{matches: result.kitchen}} percentage={false} />}
                    {result.cooling &&
                    <ResultElement withNumbers={showNumbers} name="cooling" _result={result} _data={{matches: result.cooling}} percentage={false} />}

                    {result.airCondition &&
                    <ResultElement withNumbers={showNumbers} name="airMinus5" _result={result} _data={{matches: result.airMinus5}} percentage={false} />}
                </div>
            </div>}
        </div>
        <button className='mt-10 hover:opacity-100 cursor-pointer text-allimmoDark opacity-30' onClick={() => setShowNumbers(!showNumbers)}>{showNumbers ? "HIDE NUMBERS" : "SHOW NUMBERS"}</button>
        <button className='mb-20 hover:opacity-100 cursor-pointer text-red-500 opacity-30' onClick={close}>CLOSE WINDOW</button>

    </Dialog>
  )
};

const ResultElement = ({withNumbers, name, _result, _data, calculation = null, percentage = true, visibleCondition = true}) => {
    const result = _data.matches
    const value = _result.original === 0 ? result / 20 : (result - _result.original);

    return (
        <div className={`${(result - _result.original > 0 && visibleCondition) ? "block" : "hidden"} bg-green-100 p-2 rounded-lg border border-green-700 flex flex-col items-center justify-center shadow-lg gap-3`}>
            <div className='flex flex-col items-center justify-center px-1 h-10'>
                <p className='w-full text-center text-sm'>{t(`Analysis.Result.${name}`)} {_data.reduction}{percentage ? "%" : ""}</p>
                {calculation !== null && 
                <p className='italic text-xs'><NumericFormat decimalScale={2} suffix=' €' displayType="text" thousandSeparator  value={calculation}/></p>}
            </div>
            <GaugeChart animateDuration={5000} nrOfLevels={5} hideText arcWidth={0.3} percent={value} cornerRadius={3} colors={["#d9ead3", "#6aa84f"]}/>
            {withNumbers && <p className='text-2xl font-semibold text-green-700 px-2 text-center bg-green-200 rounded-lg'>{(result - _result.original) >= 0 ? "+" : ""}{result - _result.original}</p>}
        </div>
    )
};

export default Analysis
