import { Collapse, MenuItem, TextField } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { updateProfile } from '../../helper/functions';
import Button from '../Generics/Button';
import validator from 'validator';

const CompleteProfile = () => {

    const { customer } = useStateContext();
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState({
        name: "",
        phone: "",
        yearOfBirth: "",
        gender: "-",
        code: "",
    });
    const [notFound, setNotFound] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const saveData = async () => {
      setErrorMessage("");
      if(data.yearOfBirth.length > 0) {
        if (data.yearOfBirth.trim().length !== 4 || !validator.isNumeric(data.yearOfBirth.trim())) {
          return setErrorMessage("yearOfBirth-invalid");
        }
      }

      setIsLoading(true);
      const updateResult = await updateProfile({name: data.name.trim(), phone: data.phone.trim(), code: data.code, gender: data.gender, yearOfBirth: data.yearOfBirth.trim()});
      if(updateResult.data === "not-found") {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
      setIsLoading(false);
    };

    const handleCodeInputChange = (newInput) => {
      setNotFound(false);
      setData({...data, code: newInput})
    };
    
  return (
    <div className='py-5 px-1 sm:px-2 ssm:px-5 flex flex-col justify-center items-center select-none h-screen md:h-auto'>
        {/* <p className='bg-orange-50 text-orange-500 rounded px-1 ssm:px-3 text-sm text-center mb-3'>{t('CompleteProfile.InfoText')}</p> */}
        <img src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2FAllimmomatch.png?alt=media&token=e6afb35b-6fb7-46dd-831f-4dd1b233b598" alt="ALLIMMO MATCH" width={150} />
        <p className='ssm:text-lg mb-8 w-full text-center px-3'>{t('Account.ProfilePopupHeader')}</p>
        <TextField required size='small' label={t('Account.IhrName')} className="w-full" value={data.name} onChange={(e) => setData({...data, name: e.target.value})}/>
        <p className='text-xs w-full ml-1 italic mb-3'>{t('Account.NameHelper')}</p>
        
        <div className='flex flex-col gap-2 items-center justify-center w-full border-2 border-dashed rounded px-2 pt-2 pb-2 border-allimmoDark/60'>
          <p className='text-allimmoDark/80 text-xs text-left w-full bg-allimmoDark/10 rounded p-1'>{t('Account.AdditionalDataInfo')}</p>
          <TextField size='small' label={t('Account.Telefonnummer')} className="w-full" value={data.phone} onChange={(e) => setData({...data, phone: e.target.value})}/>
          <TextField size='small' label={t('Account.Geburtsjahr')} className="w-full" value={data.yearOfBirth} onChange={(e) => setData({...data, yearOfBirth: e.target.value})}/>
          <TextField value={data.gender} onChange={(e) => setData({...data, gender: e.target.value})} className='w-full' select size='small' label={t('Account.Geschlecht')}>
            <MenuItem value="-">{'-'}</MenuItem>
            <MenuItem value="male">{t('Account.Male')}</MenuItem>
            <MenuItem value="female">{t('Account.Female')}</MenuItem>
            <MenuItem value="divers">{t('Account.Divers')}</MenuItem>
          </TextField>
        </div>
        <div className='flex flex-col gap-2 items-center justify-center w-full mt-5'>
          <TextField autoComplete='off' size='small' label={t('Account.AffiliateCode')} className="w-full" value={data.code} onChange={(e) => handleCodeInputChange(e.target.value)}/>
          <p className='w-full italic -mt-1.5 text-sm text-center'>{t('Affiliate.EinzigeMöglichkeit')}</p>
          <Collapse in={notFound}>
            <p className={`w-full italic text-red-500 text-center`}>{t('Affiliate.NichtGefunden')}</p>
          </Collapse>
        </div>
        <div className='flex flex-col space-x-2 mt-5'>
            <Button loading={isLoading} isDisabled={data?.name?.trim().length < 5} clickFunction={saveData} text={t('General.Speichern')} />
            {errorMessage !== "" && <p className='text-red-400 italic mt-1'>{t(`ErrorMessages.${errorMessage}`)}</p>}
        </div>
    </div>
  )
}

export default CompleteProfile